<template>
  <v-card dark class="hw-100" style="background-color: #091525">
    <div style="position: absolute;top: 120px;left:40%;width:20%;z-index: 2">
      <v-alert v-model="snackBarShow" color="blue" dismissible dark border="top" style="color: #fffd6a">
        <div class="d-flex justify-center">
          <span>{{ tipText }}</span>
        </div>
      </v-alert>
    </div>
    <div style="height:7%;width:100%;position: absolute;top:0;background-color: rgba(8,36,58,0.8);"></div>
    <!--body-->
    <div style="width: calc(100% - 70px); height:93%;position: absolute;top:7%; padding: 10px; display: flex; justify-content: space-between">
      <!--letf-->
      <div class=""
           style="width: 400px;height:100%;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(8,36,58,0.8);">
        <div class="" style="width: 100%; height: 150px; padding: 10px;">
          <v-row class="ma-0">
            <v-col cols="12" style="color: #2ec2fd;font-size: 20px;font-weight: bold; padding-bottom: 0px;">{{"打靶次数" }}</v-col>
            <v-col class="" cols="12">
              <v-text-field
                style="width: 80%;"
                outlined
                dense
                hide-details
                type="number"
                v-model.number="attackNum"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider style="border-color: #0088ff"></v-divider>
        <!--mid 输出-->
        <div class="" style="width: 100%; height: calc(100% - 300px); padding: 10px;">
          <v-col cols="12" style="color: #2ec2fd;font-size: 20px;font-weight: bold; padding-left: 0">精度统计</v-col>
          <div class="scroll_style_thin" style="width: 100%; height: calc(100% - 50px); overflow-y: scroll; overflow-x: hidden;">
            <v-row class="ma-0" v-for="(item, idx) in attackDict" :key="idx" style="display: flex; align-items: center;">
              <v-col cols="6" style="color: white;font-size: 18px;">{{item.name + ': ' }}</v-col>
              <v-col class="" cols="6">
                <v-text-field disabled readonly
                              style="width: 80%;"
                              outlined
                              dense
                              hide-details
                              :value="attackOutPut[item.value] == null ? '' : attackOutPut[item.value].toFixed(item.fix)"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider style="border-color: #0088ff"></v-divider>
        <div class="" style="width: 100%; height: 150px; padding: 10px; display: flex; justify-content: center; align-items: center">
          <v-btn style="border: #aaa 1px solid;border-radius: 10px;background-color: rgba(22, 66, 100, 1.0);color: white;" width="180px" height="50px" x-large @click="shootTarget">
            打靶实验
          </v-btn>
        </div>
      </div>
      <!--mid-->
      <div style="width: calc(100% - 830px);height:100%;
           display: flex; align-items: center; justify-content: center;
           border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(8,36,58,0.8);">
        <tartget-chart ref="chart" :width="600" :height="600" :max="chartMax"></tartget-chart>
      </div>
      <!--right-->
      <div class=""
           style="width: 400px;height:100%;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(8,36,58,0.8);">
        <div class="" style="width: 100%; height: calc(100% - 470px); padding: 10px;">
          <v-row class="ma-0" style="margin-bottom: 0">
            <v-col cols="12" style="color: #2ec2fd;font-size: 20px;font-weight: bold;">{{"极限拉偏参数" }}</v-col>
          </v-row>
          <div class="scroll_style_thin" style="width: 100%; height: calc(100% - 30px); overflow-y: scroll; overflow-x: hidden; display: flex; flex-direction: column;">
            <v-row class="ma-0" style="margin-bottom: 0; margin-top: 0; width: 100%; display: flex; align-items: center;" v-for="(item, idx) in exInputDict" :key="idx">
              <v-col cols="12" style="display: flex; align-items: center; padding-bottom: 0; border-bottom: 1px solid #0088ff50">
                <v-col cols="2" style="color: white;font-size: 18px;padding-top: 0; padding-bottom: 0;"> {{ idx + 1 }} </v-col>
                <v-col class="" cols="5" style="padding-top: 0; padding-bottom: 0;">
                  <v-checkbox :label="item.name" v-model="exInputCheck[item.check]"></v-checkbox>
                </v-col>
                <v-col class="" cols="4" style="padding-top: 0; padding-bottom: 0;">
                  <v-radio-group v-model="exInputRadio[item.option]" hide-details style="padding-top: 0; padding-bottom: 0; margin-top: 0">
                    <v-radio :value="-1" label="负偏离"></v-radio>
                    <v-radio :value="1" label="正偏离"></v-radio>
                  </v-radio-group>
                  <!--<v-checkbox label="正偏离"></v-checkbox>-->
                </v-col>
              </v-col>
              <!--<v-col cols="12">-->
              <!--  <v-divider style="border-color: #0088ff"></v-divider>-->
              <!--</v-col>-->
            </v-row>
          </div>
        </div>
        <v-divider style="border-color: #0088ff"></v-divider>
        <!--mid 输出-->
        <div class="" style="width: 100%; height: 320px; padding: 10px;">
          <v-col cols="12" style="color: #2ec2fd;font-size: 20px;font-weight: bold; padding-left: 0">拉偏结果</v-col>
          <v-row class="ma-0" v-for="(item, idx) in exOutDict" :key="idx" style="display: flex; align-items: center;">
            <v-col cols="6" style="color: white;font-size: 18px;">{{item.name + ': ' }}</v-col>
            <v-col class="" cols="6">
              <v-text-field disabled readonly
                style="width: 80%;"
                outlined
                dense
                hide-details
                :value="exOutPut[item.value] == null ? '' : exOutPut[item.value].toFixed(item.fix)"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-divider style="border-color: #0088ff"></v-divider>
        <div class="" style="width: 100%; height: 150px; padding: 10px; display: flex; justify-content: center; align-items: center">
          <v-btn style="border: #aaa 1px solid;border-radius: 10px;background-color: rgba(22, 66, 100, 1.0);color: white;" width="180px" height="50px" x-large @click="exBias">
            极限拉偏实验
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import TartgetChart from "@/components/TartgetChart";
import { mapState } from 'vuex'

export default {
  name: "TargetDemo",
  components: {TartgetChart},
  data(){
    return {
      snackBarShow: false,
      tipText: "计算失败，参数输入有误",
      // 图表刻度最大值
      chartMax: 40000,
      // 打靶输入
      attackNum: 30,
      attackDict: [
        {name: 'X轴平均偏差(m)', value: 'XbiasAvrage', fix: 4},
        {name: 'Y轴平均偏差(m)', value: 'YbiasAvrage', fix: 4},
        {name: 'X轴方差(m)', value: 'Xvariance', fix: 2},
        {name: 'Y轴方差(m)', value: 'Yvariance', fix: 4},
        {name: 'X轴最小值(m)', value: 'Xmin', fix: 4},
        {name: 'X轴最大值(m)', value: 'Xmax', fix: 4},
        {name: 'Y轴最小值(m)', value: 'Ymin', fix: 4},
        {name: 'Y轴最大值(m)', value: 'Ymax', fix: 4},
      ],
      attackOutPut:{ // 打靶输出
        XbiasAvrage: 0,
        YbiasAvrage: 0,
        Xvariance: 0,
        Yvariance: 0,
        Xmin: 0,
        Xmax: 0,
        Ymin: 0,
        Ymax: 0,
      },
      // 极限拉偏输入 0-负偏离 1-正偏离
      exInputDict: [
        {name: '秒耗量', check: 'bUse_dm', option: 'bOffsetFlag_dm'},
        {name: '地面推力', check: 'bUse_p0', option: 'bOffsetFlag_p0'},
        {name: '对马赫数一次项系数', check: 'bUse_ClMa', option: 'bOffsetFlag_ClMa'},
        {name: '对攻角一次项系数', check: 'bUse_Clalpha', option: 'bOffsetFlag_Clalpha'},
        {name: '对攻角二次项系数', check: 'bUse_Clalpha2', option: 'bOffsetFlag_Clalpha2'},
        {name: '对马赫数一次项系数', check: 'bUse_Cd1Ma', option: 'bOffsetFlag_Cd1Ma'},
        {name: '对攻角一次项系数', check: 'bUse_Cd1alpha', option: 'bOffsetFlag_Cd1alpha'},
        {name: '对攻角二次项系数', check: 'bUse_Cd1alpha2', option: 'bOffsetFlag_Cd1alpha2'},
        {name: '常数项系数', check: 'bUse_Cd20', option: 'bOffsetFlag_Cd20'},
        {name: '对马赫数一次项系数', check: 'bUse_Cd2Ma', option: 'bOffsetFlag_Cd2Ma'},
        {name: '对攻角一次项系数', check: 'bUse_Cd2alpha', option: 'bOffsetFlag_Cd2alpha'},
        {name: '对攻角二次项系数', check: 'bUse_Cd2alpha2', option: 'bOffsetFlag_Cd2alpha2'},
      ],
      exInputCheck: {
        bUse_dm: true,
        bUse_p0: false,
        bUse_ClMa: true,
        bUse_Clalpha: false,
        bUse_Clalpha2: false,
        bUse_Cd1Ma: false,
        bUse_Cd1alpha: false,
        bUse_Cd1alpha2: false,
        bUse_Cd20: false,
        bUse_Cd2Ma: false,
        bUse_Cd2alpha: false,
        bUse_Cd2alpha2: false,
      },
      exInputRadio: {
        bOffsetFlag_dm: -1, // '秒耗量'
        bOffsetFlag_p0: -1, // '秒耗量'
        bOffsetFlag_ClMa: -1, // '秒耗量'
        bOffsetFlag_Clalpha: -1, // '秒耗量'
        bOffsetFlag_Clalpha2: -1, // '秒耗量'
        bOffsetFlag_Cd1Ma: -1, // '秒耗量'
        bOffsetFlag_Cd1alpha: -1, // '秒耗量'
        bOffsetFlag_Cd1alpha2: -1, // '秒耗量'
        bOffsetFlag_Cd20: -1, // '秒耗量'
        bOffsetFlag_Cd2Ma: -1, // '秒耗量'
        bOffsetFlag_Cd2alpha: -1, // '秒耗量'
        bOffsetFlag_Cd2alpha2: -1, // '秒耗量'
      },

      // 极限输出
      exOutDict: [
        {name: '落点X轴偏差(m)', value: 'Xbias', fix: 4},
        {name: '落点Y轴偏差(m)', value: 'Ybias', fix: 4},
        {name: '落点速度大小(m)', value: 'LandVel', fix: 4},
        {name: '落点速度倾角(m)', value: 'LandAgel', fix: 4},
      ],
      exOutPut:{
        Xbias: 0, //X轴偏差
        Ybias: 0, //Y轴偏差
        LandVel: 0, //落地速度大小
        LandAgel: 0, //落地速度倾角
      }
    }
  },
  computed: {
    ...mapState(['paramEstInput', 'paramEstOutput', 'aeroAnalyzRet', 'phase1SimDatas', 'phase2SimDatas'])
  },
  methods: {
    // 打靶
    shootTarget(){
      // this.exportReport()
      this.chartMax = 40000
      this.$refs.chart.setTarget([])
      let p1Size = this.phase1SimDatas.lArr.length
      let p2Size = this.phase2SimDatas.lArr.length
      let para = {
        Type: 'Monte-Carlo method',
        AttackNumMonte: this.attackNum, //打靶次数
        m0: this.paramEstOutput.m0,  //起飞质量kg
        dm: this.paramEstOutput.dm,	//秒耗量 kg/s
        ts: this.paramEstOutput.t01,	//关机时间 s
        p0: this.paramEstOutput.P0,	//地面推力 N
        Sm: this.paramEstOutput.Sen,	//导弹横截面积 m2

        ClMa: this.aeroAnalyzRet.ClMa, //对马赫数一次项系数
        Clalpha: this.aeroAnalyzRet.Clalpha,  //以下为：气动参数界面显示的参数
        Clalpha2: this.aeroAnalyzRet.Clalpha2,
        Cd1Ma: this.aeroAnalyzRet.Cd1Ma,
        Cd1alpha: this.aeroAnalyzRet.Cd1alpha,
        Cd1alpha2: this.aeroAnalyzRet.Cd1alpha2,
        Cd20: this.aeroAnalyzRet.Cd20,
        Cd2Ma: this.aeroAnalyzRet.Cd2Ma,
        Cd2alpha: this.aeroAnalyzRet.Cd2alpha,
        Cd2alpha2: this.aeroAnalyzRet.Cd2alpha2,

        htop: this.paramEstInput.h2,   // 弹道顶点高度km
        alphaxL: this.paramEstInput.R * 1000 - this.phase1SimDatas.lArr[p1Size - 1],   //滑翔段射程m，界面输入时得到
        R0: 6.0,	//默认值为6.0
        step: 0.05,	//默认值为0.05
        v0_3: 0.0,  	//以下默认值均为0
        p0_3: 0.0, // 默认值
        tp0_1: this.phase2SimDatas.lArr[p2Size-1] + 5000.0,   //目标点的X位置，即界面上设置的射程  增加输入
        tp0_2: 0.0, // 默认值
        tp0_3: 0.0, // 默认值
        tv0_1: 0.0, // 默认值
        tv0_2: 0.0, // 默认值
        tv0_3: 0.0, // 默认值
      }
      // console.log('shoot para:', para)
      this.socketApi.sendSock(para, (res) => {
        // console.log('target result:', res)
        // 打靶输出
        this.attackOutPut.XbiasAvrage = res.XbiasAvrage
        this.attackOutPut.YbiasAvrage = res.YbiasAvrage
        this.attackOutPut.Xvariance = res.Xvariance
        this.attackOutPut.Yvariance = res.Yvariance
        this.attackOutPut.Xmin = res.Xmin
        this.attackOutPut.Xmax = res.Xmax
        this.attackOutPut.Ymin = res.Ymin
        this.attackOutPut.Ymax = res.Ymax
        // TODO: CHART
        let points = []
        let radio = this.chartMax / 10
        res.vLandPointXArr.forEach((x, i) => {
          let y = res.vLandPointYArr[i]
          let dis = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
          let score = Math.ceil(dis / radio)
          points.push({
            x: x,
            y: y,
            score: score
          })
        })
        // console.log('points: ', points)
        this.$refs.chart.setTarget(points)
      })
    },
    // 拉偏
    exBias(){
      this.chartMax = 1000000
      this.$refs.chart.setTarget([])
      let p1Size = this.phase1SimDatas.lArr.length
      let p2Size = this.phase2SimDatas.lArr.length
      let para = {
        Type: 'Limit-Deviation method',
        // bUse_dm: false,	//秒耗量 kg/s
        // bUse_p0: false,	//地面推力 N
        // bUse_ClMa: false,    //对马赫数一次项系数
        // bUse_Clalpha: false,  //以下为：气动参数界面显示的参数
        // bUse_Clalpha2: false,
        // bUse_Cd1Ma: false,
        // bUse_Cd1alpha: false,
        // bUse_Cd1alpha2: false,
        // bUse_Cd20: false,
        // bUse_Cd2Ma: false,
        // bUse_Cd2alpha: false,
        // bUse_Cd2alpha2: false,
        ...this.exInputCheck,

        //设置偏离标志，0=负偏离 1=正偏离
        // bOffsetFlag_dm: 0,	//秒耗量 kg/s
        // bOffsetFlag_p0: 0,	//地面推力 N
        // bOffsetFlag_ClMa: 0,    //对马赫数一次项系数
        // bOffsetFlag_Clalpha: 0,  //以下为：气动参数界面显示的参数
        // bOffsetFlag_Clalpha2: 0,
        // bOffsetFlag_Cd1Ma: 0,
        // bOffsetFlag_Cd1alpha: 0,
        // bOffsetFlag_Cd1alpha2: 0,
        // bOffsetFlag_Cd20: 0,
        // bOffsetFlag_Cd2Ma: 0,
        // bOffsetFlag_Cd2alpha: 0,
        // bOffsetFlag_Cd2alpha2: 0,
        ...this.exInputRadio,
        m0: this.paramEstOutput.m0,  //起飞质量kg
        dm: this.paramEstOutput.dm,	//秒耗量 kg/s
        ts: this.paramEstOutput.t01,	//关机时间 s
        p0: this.paramEstOutput.P0,	//地面推力 N
        Sm: this.paramEstOutput.Sen,	//导弹横截面积 m2

        ClMa: this.aeroAnalyzRet.ClMa, //对马赫数一次项系数
        Clalpha: this.aeroAnalyzRet.Clalpha,  //以下为：气动参数界面显示的参数
        Clalpha2: this.aeroAnalyzRet.Clalpha2,
        Cd1Ma: this.aeroAnalyzRet.Cd1Ma,
        Cd1alpha: this.aeroAnalyzRet.Cd1alpha,
        Cd1alpha2: this.aeroAnalyzRet.Cd1alpha2,
        Cd20: this.aeroAnalyzRet.Cd20,
        Cd2Ma: this.aeroAnalyzRet.Cd2Ma,
        Cd2alpha: this.aeroAnalyzRet.Cd2alpha,
        Cd2alpha2: this.aeroAnalyzRet.Cd2alpha2,

        htop: this.paramEstInput.h2,   // 弹道顶点高度km
        alphaxL: this.paramEstInput.R * 1000 - this.phase1SimDatas.lArr[p1Size - 1],   //滑翔段射程m，界面输入时得到
        R0: 6.0,	//默认值为6.0
        step: 0.05,	//默认值为0.05
        v0_3: 0.0,  	//以下默认值均为0
        p0_3: 0.0, // 默认值
        tp0_1: this.phase2SimDatas.lArr[p2Size-1] + 5000.0,   //目标点的X位置，即界面上设置的射程  增加输入
        tp0_2: 0.0, // 默认值
        tp0_3: 0.0, // 默认值
        tv0_1: 0.0, // 默认值
        tv0_2: 0.0, // 默认值
        tv0_3: 0.0, // 默认值
      }
      // TODO
      // console.log('extreme para:', para)
      let radio = this.chartMax / 10
      this.socketApi.sendSock(para, (res) => {
        // console.log('extreme result:', res)
        // exOutPut:{
        //   Xbias: 0, //X轴偏差
        //   Ybias: 0, //Y轴偏差
        //   LandVel: 0, //落地速度大小
        //   LandAgel: 0, //落地速度倾角
        // }
        if(res.Xbias == null || res.Ybias == null || res.LandPosX == null || res.LandPosY == null){
          this.snackBarShow = true
          this.tipText = "计算失败，参数输入有误!"
          setTimeout(() => {
            this.snackBarShow = false
          }, 1500)
        }
        Object.assign(this.exOutPut, res)
        let dis = Math.sqrt(Math.pow(res.LandPosX, 2) + Math.pow(res.LandPosY, 2))
        let score = Math.ceil(dis / radio)
        this.$refs.chart.setTarget([
          {
            x: res.LandPosX,
            y: res.LandPosY,
            score: score
          }
      ])
      })
    },

  }
}
</script>

<style scoped>
.scroll_style_thin::-webkit-scrollbar {
  background-color: rgba(5, 65, 104, 0.56);
  width: 8px;
  height: 8px;
  border-radius: 5px;
}

.scroll_style_thin::-webkit-scrollbar-thumb {
  background: #3a77a6;
  border-radius: 5px;
}
</style>
