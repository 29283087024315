<template>
  <!--:style="{width: width + 'px', height: height + 'px'}"-->
  <div class="" style="position: relative;width: 800px; height: 800px;">
    <!--width: 100%; height: 100%-->
    <div ref="radar" class="" style="width: 800px; height: 800px;"></div>
    <div ref="scatter" class="-red" style="position: absolute; top: 0; z-index: 1; width: 800px; height: 800px;"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "TargetChart",
  props: {
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    min: {
      type: Number,
      default: -40000
    },
    max: {
      type: Number,
      default: 40000
    }
  },
  // 10环
  // 中心 [0,0]
  // 最值[-10000, 10000]
  // 将打靶坐标归化最值区间，分10个等级，每个等级颜色不同
  data() {
    return {
      scatterChart: undefined,
      circleColor: {
        1: "#ff0000", // x,y ∈ Math.sqrt(x² + y²) <= 1000
        2: "#fc4949", // x,y ∈ 1000 < Math.sqrt(x² + y²) <= 2000
        3: "#fa6448", // x,y ∈ 2000 < Math.sqrt(x² + y²) <= 3000
        4: "#f68e78", // x,y ∈ 3000 < Math.sqrt(x² + y²) <= 4000
        5: "#f1b5b5", // x,y ∈ 4000 < Math.sqrt(x² + y²) <= 5000
        6: "#f5ecec", // x,y ∈ 5000 < Math.sqrt(x² + y²) <= 6000
        7: "#c1c1c1", // x,y ∈ 6000 < Math.sqrt(x² + y²) <= 7000
        8: "#a0a0a0", // x,y ∈ 7000 < Math.sqrt(x² + y²) <= 8000
        9: "#787878", // x,y ∈ 8000 < Math.sqrt(x² + y²) <= 9000
        10: "#474747", // x,y ∈ 9000 < Math.sqrt(x² + y²) <= 10000
      },
    };
  },
  watch: {
    max(val){
      if(this.radarChart){
        this.radarChart.setOption({
          radar: {
            shape: "circle",
            // splitLine: false,
            axisLine: {
              // show: false,
              // symbol: ['none', 'arrow'],
              // symbolSize: [10, 15],
              // symbolOffset: [0, 15],
            },
            // startAngle: 0,
            splitNumber: 10,
            nameGap: 20,
            indicator: [
              { name: "Y", max: 40 }, // this.max
              { name: "", max: val },
              { name: "", max: 40 }, // this.max
              { name: "X", max: val },
            ]
          }
        }, {
          replaceMerge: ['radar']
        })
      }
      if(this.scatterChart){
        this.scatterChart.setOption({
          xAxis: {
            // axisLine: false,
            splitLine: false,
            axisLabel: {
              show: true,
              rotate: -90,
              interval: 2000,
              color: '#FFF'
            },
            splitNumber: 20,
            min: -val,
            max: val,
            interval: val / 10,
          },
        }, {
          replaceMerge: ['xAxis']
        })
      }
    }
  },
  mounted() {
    this.radarChart = echarts.init(this.$refs.radar);
    let optionRadar;
    optionRadar = {
      title: {
        text: "",
      },
      legend: {
        data: ["", ""],
      },
      radar: {
        shape: "circle",
        // splitLine: false,
        axisLine: {
          // show: false,
          // symbol: ['none', 'arrow'],
          // symbolSize: [10, 15],
          // symbolOffset: [0, 15],
        },
        // startAngle: 0,
        splitNumber: 10,
        nameGap: 20,
        indicator: [
          { name: "Y", max: 40 }, // this.max
          { name: "", max: this.max },
          { name: "", max: 40 }, // this.max
          { name: "X", max: this.max },
        ],
      },
      series: [
        {
          type: "radar",
          data: [
            {
              //   value: [0, -2000],
              symbol: "circle",
              symbolSize: 10,
              itemStyle: {
                color: "#ff0000",
              },
              lineStyle: {
                opacity: 0,
              },
            },
          ],
        },
      ],
    };
    optionRadar && this.radarChart.setOption(optionRadar);

    this.scatterChart = echarts.init(this.$refs.scatter);
    let optionScatter;
    optionScatter = {
      xAxis: {
        // axisLine: false,
        splitLine: false,
        axisLabel: {
          show: true,
          rotate: -90,
          interval: 2000,
          color: '#FFF'
        },
        splitNumber: 20,
        min: -this.max,
        max: this.max,
        interval: this.max / 10,
      },
      yAxis: {
        // axisLine: false,
        splitLine: false,
        axisLabel: {
          color: '#FFF'
        },
        splitNumber: 20,
        min: -40, //this.min,
        max: 40, //this.max,
        interval: 5, //this.max / 10,
      },
      grid: {
        bottom: 99,
        left: 99,
        right: 99,
        top: 99,

      },
      series: [
        {
          symbolSize: 10,
          type: "scatter",
          symbolOffset: [0, 0],
          data: [
            // {
            //   value: [3000, 10],
            //   itemStyle: {
            //     color: "#ff0000",
            //   },
            // },
            // {
            //   value: [0, 0],
            //   itemStyle: {
            //     color: "#fff000",
            //   },
            // },
            // {
            //   value: [10000, 0],
            //   itemStyle: {
            //     color: "#fff000",
            //   },
            // },
            // {
            //   value: [0, 30],
            //   itemStyle: {
            //     color: "#ff00f0",
            //   },
            // },
          ],
        },
      ],
    };

    optionScatter && this.scatterChart.setOption(optionScatter);
  },
  methods: {
    setTarget(dataArr) {
      if (this.scatterChart) {
        let temp = dataArr.map((item) => {
          return {
            value: [item.x, item.y],
            itemStyle: {
              color: this.circleColor[item.score], // 环数
            },
          };
        });
        // this.scatterChart.clear()
        this.scatterChart.setOption({
          series: [
            {
              data: temp,
            },
          ],
        });
      }
    },
  },
};
</script>

<style scoped>
.scatter {
  position: relative;
}
</style>
