<template>
  <div style="width: 100vw; height: 100vh; position: relative">
    <div style="z-index: 2;position: absolute;top: 70px;right: 10px;text-align: right;width:70px;">
      <div style="height: 20px;color:white;"></div>

      <div class="content">
        <div @mouseenter="enter" style="width: 170px;margin-top: 70px">
          <span style="margin-right: 100px;width: 50px;margin-top: -70px;color: #ccc" class="xx"></span>
          <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 5" class="gd"
                 style="margin-top: -70px"></v-img>
          <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/view.png" @click="pageChange(5)" class="gd"
                 title="结构认知"></v-img>
        </div>
        <!--      <v-img src="@/assets/png/construction.png"  @click="pageChange(0)"></v-img>-->
        <div @mouseenter="enter1" style="width: 170px;margin-top: 70px">
          <span style="margin-right: 100px;width: 50px;margin-top: -70px;color: #ccc" class="xx"></span>
          <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 0" class="gd"
                 style="margin-top: -70px"></v-img>
          <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/construction.png" @click="pageChange(0)"
                 class="gd" title="总体参数设计"></v-img>
        </div>
      </div>

      <div @mouseenter="enter2" style="width: 170px;margin-top: 70px">
        <span style="margin-right: 100px;width: 50px;color: #ccc" class="xx"></span>
        <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 1" class="gd"
               style="margin-top: -70px"></v-img>
        <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/overall.png" @click="pageChange(1)" class="gd"
               title="模块化设计"></v-img>
      </div>

      <div @mouseenter="enter3" style="width: 170px;margin-top: 70px">
        <span style="margin-right: 100px;width: 50px;color: #ccc" class="xx"></span>
        <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 3" class="gd"
               style="margin-top: -70px"></v-img>
        <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/configuration.png" @click="pageChange(3)"
               class="gd" title="弹道规划"></v-img>
      </div>

      <div @mouseenter="enter4" style="width: 170px;margin-top: 70px">
        <span style="margin-right: 100px;width: 50px;color: #ccc" class="xx"></span>
        <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 2" class="gd"
               style="margin-top: -70px"></v-img>
        <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/plan.png" @click="pageChange(2)" class="gd"
               title="虚拟飞行验证"></v-img>
      </div>
      <div style="width: 170px;margin-top: 70px">
        <span style="margin-right: 100px;width: 50px;color: #ccc" class="xx"></span>
        <v-img width="70px" src="@/assets/png/choose.png" v-show="pageIndex == 6" class="gd"
               style="margin-top: -70px"></v-img>
        <v-img style="width: 70px;margin-top: -70px" src="@/assets/png/target.png" @click="pageChange(6)" class="gd"
               title="打靶演示"></v-img>
      </div>

      <div style="height: 20px;"></div>

    </div>
    <!--<v-row class="ma-0 border-red">-->
    <!--  <v-col class="border-red">-->
    <!--    <v-img src="@/assets/png/logotitle.png"-->
    <!--           style="width: 300px;height: 50px;z-index: 5;margin-left: 10px"></v-img>-->
    <!--  </v-col>-->
    <!--</v-row>-->
    <!--<v-row class="ma-0 border-red">-->
    <!--  <v-col style="margin-top: -50px">-->
    <!--    <v-img src="@/assets/png/title.png" style="z-index: 5;margin-top: -10px"></v-img>-->
    <!--  </v-col>-->
    <!--</v-row>-->
    <!--<div style="height:7%;width:100%;z-index: 2;position: absolute;top: 0;text-align: center;-->
    <!--            color:#59c8ff;background-color: rgba(8,36,58,0.8);font-size: 36px;">-->
    <!--  {{ title }}-->
    <!--</div>-->
    <!--<v-row>-->
    <!--  <v-col-->
    <!--      style="height:7%;width:100%;z-index: 2;top: 0;color:#59c8ff;font-size: 22px;position: absolute; right: 10px; top: 20px;">-->
    <!--    {{ subhead }}-->
    <!--  </v-col>-->
    <!--</v-row>-->
    <div class="head-bg" style="height: 60px; width: 100%;
            display: flex; align-items: center;
           position: absolute;z-index: 2">
      <div style="height: 100%; width: 320px;display: flex; align-items: center; position: absolute;">
        <v-img src="@/assets/png/logotitle.png"
               style="width: 300px;height: 50px;z-index: 5;margin-left: 10px">
        </v-img>
      </div>
      <div class="" style="width: 100%; height: 100%;
            display: flex; align-items: center;
            position: absolute;z-index: 3;">
        <v-img class="ml-2" src="@/assets/bg/head.png" height="20" contain></v-img>
      </div>
      <div class="" style="width: 100%; height:100%; position: relative;
              display: flex; align-items: center; justify-content: center;
              color:#59c8ff;background-color: rgba(8,36,58,0.8);font-size: 36px;">
        <div>
          {{ title }}
        </div>
        <div class="" style="position: absolute; right: 10px;bottom: 10px; font-size: 22px;">
          {{ subhead }}
        </div>
      </div>
    </div>
    <div class="" style="height:100%;width: 100%;">
      <paramEst v-show="pageIndex < 3 || pageIndex ===5" :page-index="pageIndex" style="margin-top: 0px"></paramEst>
      <theta0 v-show="pageIndex === 3" :page-index="pageIndex" style="margin-top: 0px"></theta0>
      <theta1 v-show="pageIndex === 4"></theta1>
      <target-demo v-show="pageIndex === 6"></target-demo>
    </div>

  </div>
</template>

<script>

import paramEst from './paramEst';
import theta0 from './theta0';
import theta1 from './theta1';
import TargetDemo from "@/views/TargetDemo";
// import alphax from './alphax';

export default {
  name: 'index',
  components: {
    paramEst, TargetDemo,
    theta0,
    theta1,
    //alphax
  },
  data() {
    return {
      ifshow: false,
      message: '',
      ifshow1: false,
      message1: '',
      ifshow2: false,
      message2: '',
      ifshow3: false,
      message3: '',
      ifshow4: false,
      message4: '',
      subhead: "助推滑翔导弹模块化设计与飞行验证",
      showIndex: false,
      pageIndex: 5,
      pageState: [1, 0, 0, 0, 0,],
      title: "结构认知",
      select: "background-color:#05bbbb;border:2px solid #0bb2fa;",
      unselect: "background-color:#091525;border:2px solid #0bb2fa;",
      fullHeight: document.documentElement.clientHeight,
      // form_data:{},
      // gameInstance:undefined,
    }
  },
  mounted() {
    this.pageChange(5)
  },
  methods: {
    enter: function () {
      this.message = '视景演示'
    },
    leave: function () {
      this.message = ''
    },
    enter1: function () {
      this.message1 = '结构认知'
    },
    leave1: function () {
      this.message1 = ''
    },
    enter2: function () {
      this.message2 = '总体设计'
    },
    leave2: function () {
      this.message2 = ''
    },
    enter3: function () {
      this.message3 = '构型设计'
    },
    leave3: function () {
      this.message3 = ''
    },
    enter4: function () {
      this.message4 = '弹道规划'
    },
    leave4: function () {
      this.message4 = ''
    },
    pageChange(val) {
      this.pageIndex = val;
      console.log(this.pageIndex)
      for (var i = 0; i < 6; i++) {
        this.pageState[i] = 0;
      }
      this.pageState[val] = 1;
      if (val === 0) {
        this.title = "总体参数设计";
        this.ifshow1 = true
        this.ifshow = false
        this.ifshow2 = false
        this.ifshow3 = false
        this.ifshow4 = false
      } else if (val === 1) {
        this.title = "模块化设计";
        this.ifshow1 = false
        this.ifshow = false
        this.ifshow2 = true
        this.ifshow3 = false
        this.ifshow4 = false
      } else if (val === 3) {
        this.title = "弹道规划";
        this.ifshow1 = false
        this.ifshow = false
        this.ifshow2 = false
        this.ifshow3 = true
        this.ifshow4 = false
      } else if (val === 5) {
        this.title = "结构认知";
        this.ifshow1 = false
        this.ifshow = true
        this.ifshow2 = false
        this.ifshow3 = false
        this.ifshow4 = false
      } else if (val === 2) {
        this.title = "虚拟飞行验证";
        this.ifshow1 = false
        this.ifshow = false
        this.ifshow2 = false
        this.ifshow3 = false
        this.ifshow4 = true
      } else if(val === 6){ // 打靶演示
        this.title = "打靶演示";
      }
    },

    /*pageChange(val){
      this.pageIndex = val;
      for (val=0;val<5;val++)
      if (this.current + 1 === val)
      {
        this.title = "视景演示"
      }
      if (this.current + 1 === val)
      {
        this.title = "总体参数设计"
      }
      if (this.current + 1 === val)
      {
        this.title = "模块化设计"
      }
      if (this.current + 1 === val)
      {
        this.title = "弹道规划"
      }
      if (this.current + 1 === val)
      {
        this.title = "飞行性能仿真"
      }
    },*/

    /*$(document).ready(function() {
      $(".img_container").mouseover(function (){
        $(".img_container.mask").show();
      });

      $(".img_container").mouseout(function (){
        $(".img_container.mask").hide();
      });
    })*/

  }
}
</script>

<style scoped>
.head-bg {
  /*background-image: url("~@/assets/bg/head.png");*/
}

.hw-100 {
  width: 100%;
  height: 100%
}

.h-100 {
  height: 100%
}

.w-100 {
  width: 100%;
}

.icon-btn {
  width: 50px;
  height: 50px;
}

.content > div.img_container {
  position: relative;
  padding: 0;
  width: 400px;
  height: 250px;
}

.img_container img {
  width: 400px;
  height: 250px;
}

.img_container .mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  width: 400px;
  background: rgba(0, 0, 0, 0.7);
  line-height: 250px;
  text-align: center;
  display: none;
}

.gd {
  position: absolute;
  z-index: 1;
}

.xx {
  position: relative;
  z-index: 2;
}

.border-red {
  border: 1px solid red;
}
</style>
