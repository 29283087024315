<template>
    <div class="" style="height: 100%; width: 100%;">
      <div style="height:100%">

        <div class="webgl-content hw-100" style="z-index: 1">
          <div id="gameContainer" style="position: absolute;z-index:1" class="hw-100">

          </div>
        </div>
        <div style="position: absolute;top: 120px;left:40%;width:20%;z-index: 2">
          <v-alert v-model="snackBarShow" color="blue" dismissible dark border="top" style="color: #fffd6a">
            <div class="d-flex justify-center">
              <span>{{ tipText }}</span>
            </div>
          </v-alert>
        </div>
        <!--      左侧结果展示区-->
        <!--      -->
        <div v-show="pageID < 2" style="position: absolute;top: 100px;left:20px;width:20%;z-index: 2">
          <!--        <div v-bind:class="pageID ===0? 'dataArea0 pl-5':'dataArea1 pl-5'">-->
          <div>
            <div class="left-parameter">
              <v-img src="@/assets/png/1.png"></v-img>
            </div>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "起飞质量(kg): " : "LREF(总长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.m0.toFixed(3) : parseFloat(aeroParam.unityData.LREF).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "推进剂质量(kg): " : "DNOSE(弹体直径m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.mf.toFixed(3) : parseFloat(aeroParam.unityData.Diameter).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "秒耗量(kg/s): " : "LCENTER(弹体长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.dm.toFixed(3) : parseFloat(aeroParam.unityData.CylinderLenght).toFixed(3) }}
              </v-col>
            </v-row>

            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "结构比: " : "LNOSE(头部长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.uk.toFixed(3) : parseFloat(aeroParam.unityData.ConeLenght).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "初始发射角(deg): " : "CHORD1(舵面底部长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{
                  pageID === 0 ? (paramRet.theta0 * 180.0 / Math.PI).toFixed(3) : parseFloat(aeroParam.unityData.CHORD1[0]).toFixed(3)
                }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "关机时间(s): " : "CHORD2(舵面顶部长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.t01.toFixed(3) : parseFloat(aeroParam.unityData.CHORD2[0]).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "关机点速度倾角(deg): " : "CHORD3(翼面底部长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{
                  pageID === 0 ? (paramRet.theta1 * 180.0 / Math.PI).toFixed(3) : parseFloat(aeroParam.unityData.CHORD1[1]).toFixed(3)
                }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="12" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "关机点速度(m/s): " : "CHORD4(翼面顶部长度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.v1.toFixed(3) : parseFloat(aeroParam.unityData.CHORD2[1]).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "滑翔起点速度(m/s): " : "SSPNAN2(舵面高度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.v2.toFixed(3) : parseFloat(aeroParam.unityData.SSPAN2[0]).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="8" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "滑翔段射程(km): " : "SSPNAN4(翼面高度m)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{
                  pageID === 0 ? (paramRet.R23 / 1000.0).toFixed(3) : parseFloat(aeroParam.unityData.SSPAN2[1]).toFixed(3)
                }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "地面推力(kN): " : "PHIF_1(舵面偏转角°)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{
                  pageID === 0 ? (paramRet.P0 / 1000.0).toFixed(3) : parseFloat(aeroParam.unityData.PHIF2[0]).toFixed(3)
                }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right: -30px">
                {{ pageID === 0 ? "发动机长度(m): " : "PHIF_2(翼面偏转角°)" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.Len.toFixed(3) : parseFloat(aeroParam.unityData.PHIF1[0]).toFixed(3) }}
              </v-col>
            </v-row>
            <v-row class="row-fixh30">
              <v-col cols="6" md="6" lg="6" style="color: white;font-size: 15px;margin-right:-30px">
                {{ pageID === 0 ? "导弹横截面积(m^2): " : "" }}
              </v-col>
              <v-col cols="4" style="color: #fffd6a;margin-left: 10%">
                {{ pageID === 0 ? paramRet.Sen.toFixed(3) : "" }}
              </v-col>
            </v-row>


            <div class="second-parameter">
              <v-img src="@/assets/png/2.png" v-show="pageID ===1"></v-img>
            </div>
            <div style="margin-left: -10px;margin-top: 10px">
              <v-row class="row-fixh30" v-show="pageID ===1" style="margin-top: 80px">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "C1Ma(对马赫数一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.ClMa.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "C1alpha(对攻角一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Clalpha.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "C1alpha2(对攻角二次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Clalpha2.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd1Ma(对马赫数一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd1Ma.toFixed(3) }}</v-col>
              </v-row>

              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd1alpha(对攻角一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd1alpha.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd1alpha2(对攻角二次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd1alpha2.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd20(常数项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd20.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd2Ma(对马赫数一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd2Ma.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd2alpha(对攻角一次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd2alpha.toFixed(3) }}</v-col>
              </v-row>
              <v-row class="row-fixh30" v-show="pageID ===1">
                <v-col cols="7" style="color: white;font-size: 14px;">{{ "Cd2alpha2(对攻角二次项系数)" }}</v-col>
                <v-col cols="4" style="color: #fffd6a">{{ aeroAnalyzRet.Cd2alpha2.toFixed(3) }}</v-col>
              </v-row>
            </div>
          </div>

        </div>

        <!--      -->
        <!--      -->
        <div v-show="pageID ===0"
             style="position: absolute;width:100%;height:18%;z-index: 2;background-color: rgba(8,36,58,0.8);bottom: 0px">
          <v-card class="hw-100 pt-3" flat color="#1e1e1e80" dark>
            <v-row class="row-fixh50 ma-0">
              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "最大射程(km)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: 0px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.R></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "弹头质量(kg)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -50px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.mh></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "弹道顶点高度(km)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -10px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.h2></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "关机点高度(km)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: 0px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.h1></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "落地速度(m/s)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -40px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.v3></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "零升阻力系数" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -40px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.Cd0></v-text-field>
              </v-col>
            </v-row>
            <v-row class="row-fixh50 ma-0 align-center" style="">
              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "地面比冲(s)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: 0px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.Isp></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "初始推重比" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -50px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.k></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "平均升阻比" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -10px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.lambd></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "发动机结构质量因数" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: 0px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.aen></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "尾段质量因数" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -40px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.N></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "导弹直径(mm)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -40px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.p0></v-text-field>
              </v-col>
            </v-row>
            <v-row class="row-fixh50 ma-0">
              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "推进剂密度(kg/m^3)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: 0px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.pf></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "装填系数" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -50px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.z></v-text-field>
              </v-col>

              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "发动机喷管长度(m)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;margin-left: -10px"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.Lp></v-text-field>
              </v-col>
              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "对地波束半锥角(deg)" }}</v-col>
              <v-col class="py-0" cols="1">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              style="width: 100px;"
                              type="number"
                              dense
                              hide-details
                              v-model.number=form_data.waveHalfAngle></v-text-field>
              </v-col>
              <v-col class="py-0" cols="1" style="color: white;font-size: 14px;">{{ "布局方案" }}</v-col>
              <v-col class="py-0" cols="2">
                <v-radio-group hide-details label="" style="width:250px;font-size: 20px;color: white; margin-left: -40px;" class="mt-0" v-model="tail_style"
                               mandatory row>
                  <v-radio :value="0" label="正常" dark></v-radio>
                  <v-radio :value="1" label="鸭式" dark></v-radio>
                  <v-radio :value="2" label="无尾" dark></v-radio>
                </v-radio-group>
              </v-col>
              <!--<v-col cols="1" ></v-col>-->
              <v-col cols="1" class="py-0" style="position: relative; margin-left: -90px;">
                <div class="btn-logo">
                  <v-img src="@/assets/png/btn.png"></v-img>
                </div>
                <v-btn style="color: white;background-color: rgba(0,0,0,0)"
                       width="150px" height="38px" x-large @click="sendBasicParam">
                  计算总体参数
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div v-show="pageID ===1" style="position: absolute;bottom: 0;right:10px;width:30%;height:18%;z-index: 2">
          <v-card class="hw-100 " flat color="#1e1e1e00" dark>
            <div style="float: right">
              <div class="mt-2">
                <v-btn width="180px" height="50px" class="mr-5"
                       style="border: #aaa 1px solid;border-radius: 15px;background-color: rgba(22, 66, 100, 1.0);color: white;"
                       x-large @click="getUnityData">
                  获取结构数据
                </v-btn>
              </div>
              <div class="mt-2">
                <v-btn width="180px" height="50px" class="mr-5 mt-5"
                       style="border: #aaa 1px solid;border-radius: 15px;background-color: rgba(22, 66, 100, 1.0);color: white;"
                       x-large @click="sendAeroParam">
                  计算气动参数
                </v-btn>
              </div>
              <div class="mt-2">
                <v-btn width="180px" height="50px" class="mr-5 mt-5"
                       style="border: #aaa 1px solid;border-radius: 15px;background-color: rgba(22, 66, 100, 1.0);color: white;"
                       x-large @click="exportReport">
                  生成报告
                </v-btn>
              </div>

              <!--            <div class="mt-2">-->
              <!--              <v-btn  width="180px" height="50px" class="xy-btn mr-5" x-large color="#1e1e1e50" @click="downloadParamFile" dark>-->
              <!--                下载参数文件-->
              <!--              </v-btn>-->
              <!--            </div>-->

            </div>

          </v-card>
        </div>
      </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import {exportExcel, exportExcelMulSheet} from "@/until/util";

export default {
  name: 'index',
  props: ['pageIndex'],
  mounted() {

    this.gameInstance = window.$unity.UnityLoader.instantiate("gameContainer", "Build/2019.json", {onProgress: window.$unity.UnityProgress});

    window['DesignData'] = this.DesignData

    // this.aeroParam = this.aeroParam.toFixed(3)
  },
  computed: {
    ...mapState(['phase1SimDatas', 'phase2SimDatas', 'phase3SimDatas'])
  },
  data() {
    return {
      tail_style: 0,
      snackBarShow: false,
      tipText: "计算完成!",
      snackTime: 3000,
      unityIn: {},
      form_data: {
        Type: "ParamEst",
        R: 500.000,
        mh: 300,
        h2: 50.000,
        h1: 20.000,
        v3: 500,
        Cd0: 0.25,
        Isp: 250,
        k: 4,
        lambd: 2.6,
        aen: 0.1,
        N: 0.1,
        p0: 560,
        pf: 1750,
        z: 0.86,
        Lp: 0.7,
        waveHalfAngle: 20,
        tail_style: 0
      },
      paramRet: {
        m0: 0.0,
        mf: 0.0,
        dm: 0.0,
        t01: 0.0,
        theta1: 0.0,
        theta0: 0.0,
        v1: 0.0,
        v2: 0.0,
        R23: 0.0,
        uk: 0.0,
        P0: 0.0,
        Len: 0.0,
        Sen: 0.0
      },
      aeroParam: {
        Type: "AeroAnalyz",
        uuid: "",
        unityData: {
          LREF: '4.959',
          SREF: '',
          BNOSE: '',
          X: [],
          R: [],
          XLE1: [],
          PHIF1: [0.520],
          PHIF2: [0.520],
          XLE2: [],
          SSPAN1: [],
          SSPAN2: [0.400, 0.320],
          CHORD1: [2.500, 0.000],
          CHORD2: [2.182, 0.000],
          LMAXU1: [],
          LMAXL1: [],
          LFLATU1: [],
          LFLATL1: [],
          ZUPPER1: [],
          ZLOWER1: [],
          LMAXU2: [],
          LMAXL2: [],
          LFLATU2: [],
          LFLATL2: [],
          ZUPPER2: [],
          ZLOWER2: [],
          Diameter: '0.560',
          ConeLenght: '0.115',
          CylinderLenght: '4.259'
        },

        // LREF:6.206278,
        // DNOSE:0.5080414,
        // LNOSE:0.7110137,
        // DCENTR:0.255,
        // BNOSE:2.9,
        // LCENTR:5.495264,
        // SSPAN_1:0.1275,
        // SSPAN_2:0.240,
        // SSPAN_3:0.1275,
        // SSPAN_4:0.2400,
        // CHORD_1:0.87,
        // CHORD_2:0.71,
        // CHORD_3:0.25,
        // CHORD_4:0.25,
        // XLE_1:3.58,
        // XLE_2:3.74,
        // XLE_3:4.70,
        // XLE_4:4.70,
        // NPANEL_1:4,
        // NPANEL_2:4,
        // PHIF_1:0.0,
        // PHIF_2:0.0,
      },
      aeroAnalyzRet: {
        ClMa: 0.0,
        Clalpha: 0.0,
        Clalpha2: 0.0,
        Cd1Ma: 0.0,
        Cd1alpha: 0.0,
        Cd1alpha2: 0.0,
        Cd20: 0.0,
        Cd2Ma: 0.0,
        Cd2alpha: 0.0,
        Cd2alpha2: 0.0,
        AeroAnalyzRet: null
      },
      pageID: 5,
      gameInstance: undefined,
      timer: undefined,
    }
  },

  watch: {
    pageIndex: function (newVal) {
      this.pageID = newVal
      if (newVal === 2) {
        this.gameInstance.SendMessage("Data", "GetDriveValue", JSON.stringify(this.phase1SimDatas))
        this.gameInstance.SendMessage("Data", "GetDriveValue", JSON.stringify(this.phase2SimDatas))
        this.gameInstance.SendMessage("Data", "GetDriveValue", JSON.stringify(this.phase3SimDatas))
        // console.log("sendData:"+JSON.stringify(this.phase1SimDatas)+"-"+JSON.stringify(this.phase2SimDatas)+"-"+JSON.stringify(this.phase3SimDatas))
      }
      this.gameInstance.SendMessage("Data", "DisplayPort", newVal.toString())
    },
    tail_style: function (newVal) {
      this.form_data.tail_style = newVal;
      this.socketApi.sendSock(this.form_data, this.delCalRet);
    },
  },
  methods: {
    // 导出报表 demo
    exportReport(){
      // demo-1 单个sheet
      // const fields = ['名称', '高度']
      // const attribute = ['name', 'alt']
      // const list = [
      //   {name: 'n-1', alt: 100},
      //   {name: 'n-2', alt: 200},
      //   {name: 'n-3', alt: 300},
      //   {name: 'n-4', alt: 400},
      // ]
      // exportExcel(fields, attribute, list, 'xxx', '实验报告')
      // demo-2 多个sheet

      // LREF(总长度m) aeroParam.unityData.LREF
      // DNOSE(弹体直径m) aeroParam.unityData.Diameter
      // LCENTER(弹体长度m) aeroParam.unityData.CylinderLenght
      // LNOSE(头部长度m) aeroParam.unityData.ConeLenght
      // CHORD1(舵面底部长度m) aeroParam.unityData.CHORD1[0]
      // CHORD2(舵面顶部长度m) aeroParam.unityData.CHORD2[0]
      // CHORD3(翼面底部长度m) aeroParam.unityData.CHORD1[1]
      // CHORD4(翼面顶部长度m) aeroParam.unityData.CHORD2[1]
      // SSPNAN2(舵面高度m) aeroParam.unityData.SSPAN2[0]
      // SSPNAN4(翼面高度m) aeroParam.unityData.SSPAN2[1]
      // PHIF_1(舵面偏转角°) aeroParam.unityData.PHIF2[0]
      // PHIF_2(翼面偏转角°) aeroParam.unityData.PHIF1[0]
      let listArr = [
        {
          sheetName: '结构数据',
          fields: ['名称', '标志', '数值'],
          attribute: ['name', 'flag', 'value',],
          // attribute: ['LREF', 'DNOSE', 'LCENTER', 'LNOSE', 'CHORD1', 'CHORD2', 'CHORD3', 'CHORD4', 'SSPNAN2', 'SSPNAN4', 'PHIF_1', 'PHIF_2',],
          list: [
            {name: '总长度m', flag: 'LREF', value: this.aeroParam.unityData.LREF.toFixed(3)},
            {name: '弹体直径m', flag: 'DNOSE', value: this.aeroParam.unityData.Diameter.toFixed(3)},
            {name: '弹体长度m', flag: 'LCENTER', value: this.aeroParam.unityData.CylinderLenght.toFixed(3)},
            {name: '头部长度m', flag: 'LNOSE', value: this.aeroParam.unityData.ConeLenght.toFixed(3)},
            {name: '舵面底部长度m', flag: 'CHORD1', value: this.aeroParam.unityData.CHORD1[0].toFixed(3)},
            {name: '舵面顶部长度m', flag: 'CHORD2', value: this.aeroParam.unityData.CHORD2[0].toFixed(3)},
            {name: '翼面底部长度m', flag: 'CHORD3', value: this.aeroParam.unityData.CHORD1[1].toFixed(3)},
            {name: '翼面顶部长度m', flag: 'CHORD4', value: this.aeroParam.unityData.CHORD2[1].toFixed(3)},
            {name: '舵面高度m', flag: 'SSPNAN2', value: this.aeroParam.unityData.SSPAN2[0].toFixed(3)},
            {name: '翼面高度m', flag: 'SSPNAN4', value: this.aeroParam.unityData.SSPAN2[1].toFixed(3)},
            {name: '舵面偏转角°', flag: 'PHIF_1', value: this.aeroParam.unityData.PHIF2[0].toFixed(3)},
            {name: '翼面偏转角°', flag: 'PHIF_2', value: this.aeroParam.unityData.PHIF1[0].toFixed(3)},
          ]
        },
        // C1Ma(对马赫数一次项系数) aeroAnalyzRet.ClMa.toFixed(3)
        // C1alpha(对攻角一次项系数) aeroAnalyzRet.Clalpha.toFixed(3)
        // C1alpha2(对攻角二次项系数) aeroAnalyzRet.Clalpha2.toFixed(3)
        // Cd1Ma(对马赫数一次项系数) aeroAnalyzRet.Cd1Ma.toFixed(3)
        // Cd1alpha(对攻角一次项系数) aeroAnalyzRet.Cd1alpha.toFixed(3)
        // Cd1alpha2(对攻角二次项系数) aeroAnalyzRet.Cd1alpha2.toFixed(3)
        // Cd20(常数项系数) aeroAnalyzRet.Cd20.toFixed(3)
        // Cd2Ma(对马赫数一次项系数) aeroAnalyzRet.Cd2Ma.toFixed(3)
        // Cd2alpha(对攻角一次项系数) aeroAnalyzRet.Cd2alpha.toFixed(3)
        // Cd2alpha2(对攻角二次项系数) aeroAnalyzRet.Cd2alpha2.toFixed(3)
        {
          sheetName: '气动参数',
          fields: ['名称', '标志', '数值'],
          attribute: ['name', 'flag', 'value',],
          list: [
            {name: '对马赫数一次项系数', flag: 'C1Ma', value: this.aeroAnalyzRet.ClMa.toFixed(3)},
            {name: '对攻角一次项系数', flag: 'C1alpha', value: this.aeroAnalyzRet.Clalpha.toFixed(3)},
            {name: '对攻角二次项系数', flag: 'C1alpha2', value: this.aeroAnalyzRet.Clalpha2.toFixed(3)},
            {name: '对马赫数一次项系数', flag: 'Cd1Ma', value: this.aeroAnalyzRet.Cd1Ma.toFixed(3)},
            {name: '对攻角一次项系数', flag: 'Cd1alpha', value: this.aeroAnalyzRet.Cd1alpha.toFixed(3)},
            {name: '对攻角二次项系数', flag: 'Cd1alpha2', value: this.aeroAnalyzRet.Cd1alpha2.toFixed(3)},
            {name: '常数项系数', flag: 'Cd20', value: this.aeroAnalyzRet.Cd20.toFixed(3)},
            {name: '对马赫数一次项系数', flag: 'Cd2Ma', value: this.aeroAnalyzRet.Cd2Ma.toFixed(3)},
            {name: '对攻角一次项系数', flag: 'Cd2alpha', value: this.aeroAnalyzRet.Cd2alpha.toFixed(3)},
            {name: '对攻角二次项系数', flag: 'Cd2alpha2', value: this.aeroAnalyzRet.Cd2alpha2.toFixed(3)},
          ]
        },
      ]
      exportExcelMulSheet(listArr, '实验报告')
    },
    getUUID() {
      let s = [];
      let hexDigits = "0123456789abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";
      return s.join("");
    },
    sendBasicParam() {
      this.socketApi.sendSock(this.form_data, this.delCalRet);
      console.log(this.form_data)
    },
    getUnityData() {
      this.gameInstance.SendMessage("Data", "GetDesignData", "xxx")
    },
    DesignData(e) {
      this.aeroParam.unityData = JSON.parse(e);
      this.aeroParam.unityData.Diameter = this.aeroParam.unityData.R[10] * 2
      this.aeroParam.unityData.CylinderLenght = this.aeroParam.unityData.X[9]
      this.aeroParam.unityData.ConeLenght = this.aeroParam.unityData.X[6]


      // console.log("aeroParam:", JSON.stringify(this.aeroParam.unityData))
      //let ret = JSON.parse(e);
      //this.aeroParam = e;
      // this.aeroParam.LREF = ret.LREF
      // this.aeroParam.SREF = ret.SREF
      // this.aeroParam.BNOSE = ret.BNOSE;
      // this.aeroParam.X.slice(ret.X);
      // this.aeroParam.R.slice(ret.R);
      // this.aeroParam.XLE1:[3.334567,3.535951],
      // this.aeroParam.PHIF1:[0,90,180,270],
      // this.aeroParam.PHIF2:[0,90,180,270],
      // this.aeroParam.XLE2:[4.030752,4.926444],
      // this.aeroParam.SSPAN1:[0.615,0.375],
      //   this.aeroParam.SSPAN2:[0.615,0.615],
      //   this.aeroParam.CHORD1:[0.5625,0.4],
      //   this.aeroParam.CHORD2:[0.28125,0.32],
      //   this.aeroParam.LMAXU1:[0,0],
      //   this.aeroParam.LMAXL1:[0,0],
      //   this.aeroParam.LFLATU1:[1,1],
      //   this.aeroParam.LFLATL1:[1,1],
      //   this.aeroParam.ZUPPER1:[0.05,0.05],
      //   this.aeroParam.ZLOWER1:[0.05,0.05],
      //   this.aeroParam.LMAXU2:[0,0],
      //   this.aeroParam.LMAXL2:[0,0],
      //   this.aeroParam.LFLATU2:[1,1],
      //   this.aeroParam.LFLATL2:[1,1],
      //   this.aeroParam.ZUPPER2:[0.05,0.05],
      //   this.aeroParam.ZLOWER2:[0.05,0.05],
      //
      //
      // this.aeroParam.LNOSE = ret.m_LNOSE;
      // this.aeroParam.LCENTR = ret.m_LCENTER;
      // this.aeroParam.DNOSE = ret.m_DNOSE;
      // this.aeroParam.DCENTR = ret.m_DNOSE;
      // this.aeroParam.XLE_1 = ret.m_XLE0;
      // this.aeroParam.XLE_2 = ret.m_XLE1;
      // this.aeroParam.XLE_3 = ret.m_XLE2;
      // this.aeroParam.XLE_4 = ret.m_XLE3;
      // this.aeroParam.LREF = ret.m_LREF;
      // this.aeroParam.SSPAN_1 = ret.m_SSPAN;
      // this.aeroParam.SSPAN_2 = ret.m_SSPAN1;
      // this.aeroParam.SSPAN_3 = ret.m_SSPAN;
      // this.aeroParam.SSPAN_4 = ret.m_SSPAN2;
      // this.aeroParam.CHORD_1 = ret.m_CHORDA0;
      // this.aeroParam.CHORD_2 = ret.m_CHORDA1;
      // this.aeroParam.CHORD_3 = ret.m_CHORDB0;
      // this.aeroParam.CHORD_4 = ret.m_CHORDB1;
      // this.aeroParam.PHIF_1 = ret.m_CHORDAAngle;
      // this.aeroParam.PHIF_2 = ret.m_CHORDBAngle;

      this.snackBarShow = true
      this.tipText = "计算完成!"
      setTimeout(this.hideAlert, 1500)

      //console.log("getUnityData:"+e);
    },
    sendAeroParam() {

      this.aeroParam.uuid = this.getUUID();
      this.socketApi.sendSock(this.aeroParam, this.delAeroRet);
      //console.log("aeroParam:"+JSON.stringify(this.aeroParam))
    },
    downloadParamFile() {

    },
    delCalRet(data) {
      this.paramRet = data;
      let unityIn = {
        FuelDensity: this.form_data.pf,
        PackingFactor: this.form_data.z,
        BaseStructuralMass: data.mf,
        EngineLength: data.Len,
        EngineDiameter: Math.sqrt(data.Sen / Math.PI) * 2.0,
        NozzleLength: this.form_data.Lp,
        MissileType: this.form_data.tail_style
      }

      this.$store.commit('setParamInput', this.form_data)
      this.$store.commit('setParamOutput', data)
      // console.log("unity unity:"+JSON.stringify(unityIn));
      this.gameInstance.SendMessage("Data", "GlobalParameter", JSON.stringify(unityIn))
      this.snackBarShow = true
      this.tipText = "计算完成!"
      setTimeout(this.hideAlert, 1500)

      //console.log("paramRet:"+data)
    },
    delAeroRet(data) {
      // console.log('aeroret:', JSON.stringify(data))
      if (!data.AeroAnalyzRet) {
        this.snackBarShow = true
        this.tipText = "火箭结构参数不合理，气动计算失败!"
      } else {
        this.aeroAnalyzRet = data
        this.$store.commit('setAeroanalyzRet', data)
        this.snackBarShow = true
        this.tipText = "计算完成!"
      }
      setTimeout(this.hideAlert, 1500)

      //console.log("aeroRet:"+JSON.stringify(data))
    },
    hideAlert() {
      this.snackBarShow = false
    },
    beforeDestroy() {
      clearTimeout(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
.hw-100 {
  width: 100%;
  height: 100%
}

.h-100 {
  height: 100%
}

.w-100 {
  width: 100%;
}

.dataArea0 {
  background-image: url("../assets/png/dataBorder0.png");
  background-repeat: no-repeat;
  height: 420px;
}

.dataArea1 {
  background-image: url("../assets/png/dataBorder1.png");
  background-repeat: no-repeat;
  height: 720px;
}

.row-fixh30 {
  z-index: 1;
  height: 30px;
}

.row-fixh50 {
  height: 50px;
  display: flex;
  align-items: center;
}

.xy-input {
  height: 25px;
  color: #00ffff;
  border: 1px solid #505050;
  border-radius: 5px;
  margin-left: -20px;
}

.btn-pos {
  position: absolute;
  bottom: -5px;
  right: 100px;
}

.left-parameter {
  margin-top: -10px;
  margin-left: -10px;
  width: 300px;
  z-index: -1;
  position: absolute;
}

.btn-logo {
  width: 150px;
  z-index: -1;
  position: absolute;
}

.second-parameter {
  margin-left: -10px;
  margin-top: 50px;
  width: 300px;
  z-index: -1;
  position: absolute;
}
::v-deep{
.v-input--radio-group legend.v-label{
  font-size: 14px;
}
}
</style>
