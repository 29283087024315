<template>
  <v-app>
  <v-card dark class="hw-100" style="background-color: #091525">
    <div style="height:7%;width:100%;position: absolute;top:0;background-color: rgba(8,36,58,0.8);"  >
    </div>
    <div style="height:80%;position: absolute;top:7%;"  >
      <v-row>
       <div class="ml-7 mt-8" style="width: 210px;height:765px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(8,36,58,0.8);">
         <v-col cols="1.5">
            <div style="color: #2ec2fd;" class="pl-5 mt-3">输入参数</div>
            <v-row class="row-fixh30 pl-5 mt-5">
              <v-col cols="12" style="color: white;font-size: 13px; padding-bottom: 0px;">{{"射程(km): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{ (paramEstInput.R).toFixed(3)}}</v-col>-->
              <v-col class="" cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstInput.R"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"弹道顶点高度(km): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{ (paramEstInput.h2).toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense type="number"
                    hide-details
                    v-model="paramEstInput.h2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"起飞质量(kg): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{paramEstOutput.m0.toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstOutput.m0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"秒耗量(kg): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{ paramEstOutput.dm.toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstOutput.dm"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"关机时间(s): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{paramEstOutput.t01.toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstOutput.t01"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"地面推力(N): " }}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{paramEstOutput.P0.toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstOutput.P0"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="color: white;font-size: 13px;padding-bottom: 0px;">{{"特征面积(m^2): "}}</v-col>
<!--              <v-col cols="12" style="color: #fffd6a;margin-top: -10px;margin-left: 1.5em">{{paramEstOutput.Sen.toFixed(3)}}</v-col>-->
              <v-col cols="12">
                <v-text-field
                    style="width: 80%;"
                    outlined
                    dense
                    hide-details
                    v-model="paramEstOutput.Sen"
                ></v-text-field>
              </v-col>

              <v-col cols="12" style="color: white;font-size: 12px;">{{""}}</v-col>
              <v-col cols="12" style="color: #fffd6a">{{""}}</v-col>
              <v-col cols="12" style="color: white;font-size: 12px;">{{""}}</v-col>
              <v-col cols="12" style="color: #fffd6a">{{""}}</v-col>
              <v-col cols="12" style="color: white;font-size: 12px;">{{""}}</v-col>
              <v-col cols="12" style="color: #fffd6a">{{""}}</v-col>
            </v-row>
         </v-col>
       </div>

        <div style="width: auto;" class="pl-3 mt-5" >
          <v-col cols="6.5">
          <v-row >
            <v-col cols="8">
              <div id="lineSH" style="height: 500px;width: 855px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);margin-top: -10px"/>
            </v-col>
            <v-col cols="4">
              <div id="lineTS" style="height: 240px;width: 420px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);margin-top: -10px;margin-left: -10px"/>
              <div id="lineTT" style="margin-top:20px;height: 240px;width: 420px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);margin-left: -10px"/>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="4">
              <div id="lineTH" style="height: 240px;width: 420px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);"/>
            </v-col>
            <v-col cols="4">
              <div id="lineTA" style="height: 240px;width: 420px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);margin-left: -10px"/>
            </v-col>
            <v-col cols="4">
              <div id="lineTG" style="height: 240px;width: 420px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(26,66,100,0.6);margin-left: -15px"/>
            </v-col>
          </v-row>
          </v-col>
        </div>
        <div style="width: 310px;margin-left: 1540px;position: absolute" class="pl-5 mt-8" >
          <div style="height:240px;border: 1px solid #0bb2fa;border-radius: 15px;background-color: rgba(8,36,58,0.8);">
            <v-col  cols="1.5">
            <div style="color: #2ec2fd;" class="pl-5 mt-3">升力系数</div>
            <v-row class="row-fixh30 flex-center pl-5 mt-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"ClMa: " }}</v-col>
              <v-col cols="6">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              dense
                              hide-details
                              v-model.number=aeroParamStr.ClMa></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Clalpha" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              dense
                              hide-details
                              v-model.number=aeroParamStr.Clalpha></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Clalpha2" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              dense
                              hide-details
                              v-model.number=aeroParamStr.Clalpha2></v-text-field>
              </v-col>
            </v-row>
            </v-col>
          </div>

          <div style="height:505px;border: 1px solid #0bb2fa;border-radius: 15px;margin-top: 20px;background-color: rgba(8,36,58,0.8);">
            <div style="color:  #2ec2fd;" class="pl-5 mt-3">阻力系数</div>
            <div style="color: white;text-align: center;margin-top: 10px;">Ma ‹ 1.1</div>
            <v-row class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="" style="color: white;font-size: 12px;">{{"Cd1Ma" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number" v-model.number=aeroParamStr.Cd1Ma></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="" style="color: white;font-size: 12px;">{{"Cd1alpha" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd1alpha
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd1alpha2" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd1alpha2
                ></v-text-field>
              </v-col>
            </v-row>
            <div style="color: white;text-align: center;margin-top: -15px;">Ma >= 1.1</div>
            <v-row class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd20" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd20
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2Ma" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd2Ma
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2alpha" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd2alpha
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: -20px;" class="row-fixh30 flex-center pl-5">
              <v-col cols="5" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2alpha2" }}</v-col>
              <v-col cols="6">
                <v-text-field outlined
                              dense
                              hide-details
                              :rules="[ v=> !!v||'输入不能为空']"
                              type="number"
                              v-model.number=aeroParamStr.Cd2alpha2
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
    </div>

    <div style="position: absolute;bottom: 0;width:100%;height:100px;z-index: 2;background-color: rgba(8,36,58,0.8);" >
        <v-row class="ma-0" style="height:100%; display: flex; align-items: center;" >
          <v-col cols="3">

          </v-col>
          <v-col cols="1" class=""  style="color: white;font-size: 15px;">{{"发射倾角(deg)"}}</v-col>
          <v-col cols="1" class="">
            <v-text-field  outlined
                           style="color:#00e487;"
                           :rules="[ v=> !!v||'输入不能为空']"
                           type="number"
                           dense
                           hide-details
                           v-model.number= theta0
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="" style="color: white;font-size: 15px;">{{"上拉攻角(deg)" }}</v-col>
          <v-col cols="1" class="">
            <v-text-field  outlined
                           :rules="[ v=> !!v||'输入不能为空']"
                           type="number"
                           dense
                           hide-details
                           v-model.number= alphaxUp
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="" style="color: white;font-size: 15px;">{{"下压攻角(deg)" }}</v-col>
          <v-col cols="1" class="">
            <v-text-field  outlined
                           :rules="[ v=> !!v||'输入不能为空']"
                           type="number"
                           dense
                           hide-details
                           v-model.number= alphaxDown
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn class="" style="border: #aaa 1px solid;border-radius: 15px;background-color: rgba(22, 66, 100, 1.0);color: white;" width="180px" height="50px" x-large  @click="Plan" >
              规划
            </v-btn>
          </v-col>
        </v-row>
    </div>
  </v-card>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  // import lineEcharts from '../components/chart/lineChart.vue';
  import echarts from 'echarts';
  export default {
    name: 'index',
    props:['pageIndex'],
    mounted() {

      //this.gameInstance = window.$unity.UnityLoader.instantiate("gameContainer", "Build/2019.json", {onProgress: window.$unity.UnityProgress});

      this.shChart = echarts.init(document.getElementById('lineSH'))
      this.shChart.setOption(this.lineSHOption)

      this.tsChart = echarts.init(document.getElementById('lineTS'))
      this.tsChart.setOption(this.lineTSOption)

      this.thChart = echarts.init(document.getElementById('lineTH'))
      this.thChart.setOption(this.lineTHOption)

      this.taChart = echarts.init(document.getElementById('lineTA'))
      this.taChart.setOption(this.lineTAOption)

      this.ttChart = echarts.init(document.getElementById('lineTT'))
      this.ttChart.setOption(this.lineTTOption)

      this.tgChart = echarts.init(document.getElementById('lineTG'))
      this.tgChart.setOption(this.lineTGOption)
    },
    data(){
      return{
        //gameInstance:undefined,
        phaseIndex:2,
        pageID:3,
        theta0:0.0,
        alphaxL:400000.0,
        st0h:50000.0,
        st0s:1500.0,
        alphaxUp:0.0,
        alphaxDown:0.0,
        alphaxT:0.0,
        shChart:'',
        tsChart:'',
        thChart:'',
        ttChart:'',
        tgChart:'',
        thetaParam:{
          Type:"",
          m0:0.0,
          dm:0.0,
          ts:0.0,
          p0:0.0,
          Sm:0.0,
          htop:0.0,
          theta0:0.0,
          R0:6.0,
          step:0.05,
          v0_1:0.0,
          v0_2:0.0,
          v0_3:0.0,
          p0_1:0.0,
          p0_2:0.0,
          p0_3:0.0,
          tv0_1:0.0,
          tv0_2:0.0,
          tv0_3:0.0,
          tp0_1:206000.0,
          tp0_2:0.0,
          tp0_3:0.0,
          ClMa:0.0,
          Clalpha:0.0,
          Clalpha2:0.0,
          Cd1Ma:0.0,
          Cd1alpha:0.0,
          Cd1alpha2:0.0,
          Cd20:0.0,
          Cd2Ma:0.0,
          Cd2alpha:0.0,
          Cd2alpha2:0.0
        },
        aeroParamStr:{},
        form_data:{},
        lineSHOption:{
          grid:{
            x:40,
            bottom:'8%'
          },
          // grid:[{bottom:'10%'},{x:20}],
          title: {
            text: '射程-高度',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'高度(km)',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'2',
              },
              symbol:['none','arrow'],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'射程(km)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'2',
              },
              symbol:['none','arrow'],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#FFFF00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#FFB6C1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTSOption:{
          title: {
            text: '时间-速度',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'速度(m/s)',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              symbol:['none','arrow'],
            },
            boundaryGap:true,
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'时间(s)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              onZero:false,
              symbol:['none','arrow'],
            },
            nameTextStyle:{
              padding:[0,10,0,-15],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          grid:[{bottom:'10%'}],
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffff00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffb6c1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTHOption:{
          grid:{
            x:40,
            bottom:'10%',
          },
          title: {
            text: '时间-高度',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'高度(km)',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              symbol:['none','arrow'],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'时间(s)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              onZero:false,
              symbol:['none','arrow'],
            },
            nameTextStyle:{
              padding:[0,10,0,-15],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffff00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffb6c1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTAOption:{
          grid:{
            x:40,
            bottom:'10%',
          },
          title: {
            text: '时间-攻角',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'攻角(deg)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              onZero:false,
              symbol:['none','arrow'],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'时间(s)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              symbol:['none','arrow'],
            },
            nameTextStyle:{
              padding:[0,10,0,-15],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffff00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffb6c1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTGOption:{
          grid:{
            x:40,
            bottom:'10%',
          },
          title: {
            text: '时间-法向过载',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'法向过载(g)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              symbol:['none','arrow'],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'时间(s)',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              onZero:false,
              symbol:['none','arrow'],
            },
            nameTextStyle:{
              padding:[0,10,0,-15],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffff00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              // smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffb6c1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              // smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTTOption:{
          grid:{
            x:40,
            bottom:'10%',
          },
          title: {
            text: '时间-速度倾角',
            x:'center',
            y:'top',
            textStyle:{
              color:'#2ec2fd',
              textAlign:'center',
            },
          },
          yAxis: {
            name:'速度倾角(deg)',
            type:'value',
            // nameLocation:'middle',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              symbol:['none','arrow'],
            },
            boundaryGap:false,
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          xAxis: {
            name:'时间(s)',
            // nameLocation:'middle',
            type:'value',
            axisLine:{
              lineStyle:{
                type:'solid',
                color:'#cccccccc',
                width:'1',
              },
              onZero:false,
              symbol:['none','arrow'],
            },
            nameTextStyle:{
              padding:[0,10,0,-15],
            },
            splitLine:{
              show:true,
              lineStyle:{
                color:'#003377',
              }
            },
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffff00'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffb6c1'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        simHeaders: [
          {
            text: "时间(s)"
          },
          {
            text: "速度(km/s)"
          },
          {
            text: "高度(km)"
          },
          {
            text: "速度倾角(deg)"
          }
        ],
        simDatas: [],
        phase1SimDatas:{
          ridus:20,
          phase:[],
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          alphaArr:[],
          gArr:[],
          thetaArr:[]
        },
        phase2SimDatas:{
          ridus:20,
          phase:[],
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          alphaArr:[],
          gArr:[],
          thetaArr:[]
        },
        phase3SimDatas:{
          ridus:20,
          phase:[],
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          alphaArr:[],
          gArr:[],
          thetaArr:[]
        },
      }
    },
    computed:{
      ...mapState(['paramEstInput','paramEstOutput','aeroAnalyzRet']), // 'paramEstInput',
    },
    watch:{
      pageIndex:function (newVal){
        this.pageID = newVal
        if(newVal == 3)
        {
          this.aeroParamStr.ClMa=this.aeroAnalyzRet.ClMa.toFixed(3);
          this.aeroParamStr.Clalpha=this.aeroAnalyzRet.Clalpha.toFixed(3);
          this.aeroParamStr.Clalpha2=this.aeroAnalyzRet.Clalpha2.toFixed(3);
          this.aeroParamStr.Cd1Ma=this.aeroAnalyzRet.Cd1Ma.toFixed(3);
          this.aeroParamStr.Cd1alpha=this.aeroAnalyzRet.Cd1alpha.toFixed(3);
          this.aeroParamStr.Cd1alpha2=this.aeroAnalyzRet.Cd1alpha2.toFixed(3);
          this.aeroParamStr.Cd20=this.aeroAnalyzRet.Cd20.toFixed(3);
          this.aeroParamStr.Cd2Ma=this.aeroAnalyzRet.Cd2Ma.toFixed(3);
          this.aeroParamStr.Cd2alpha=this.aeroAnalyzRet.Cd2alpha.toFixed(3);
          this.aeroParamStr.Cd2alpha2=this.aeroAnalyzRet.Cd2alpha2.toFixed(3);
        }
      }
    },
    methods:{

      //一键顺序执行完三段
      Plan(){

        this.thetaParam.ClMa=this.aeroAnalyzRet.ClMa;
        this.thetaParam.Clalpha=this.aeroAnalyzRet.Clalpha;
        this.thetaParam.Clalpha2=this.aeroAnalyzRet.Clalpha2;
        this.thetaParam.Cd1Ma=this.aeroAnalyzRet.Cd1Ma;
        this.thetaParam.Cd1alpha=this.aeroAnalyzRet.Cd1alpha;
        this.thetaParam.Cd1alpha2=this.aeroAnalyzRet.Cd1alpha2;
        this.thetaParam.Cd20=this.aeroAnalyzRet.Cd20;
        this.thetaParam.Cd2Ma=this.aeroAnalyzRet.Cd2Ma;
        this.thetaParam.Cd2alpha=this.aeroAnalyzRet.Cd2alpha;
        this.thetaParam.Cd2alpha2=this.aeroAnalyzRet.Cd2alpha2;

        this.thetaParam.R = this.paramEstInput.R; // 射程
        this.thetaParam.htop = this.paramEstInput.h2;
        this.thetaParam.m0 = this.paramEstOutput.m0;
        this.thetaParam.dm = this.paramEstOutput.dm;
        this.thetaParam.ts = this.paramEstOutput.t01;
        this.thetaParam.p0 = this.paramEstOutput.P0;
        this.thetaParam.Sm = this.paramEstOutput.Sen;

        this.thetaParam.Type = "Theta0Plan";
        console.log(this.thetaParam)
        this.socketApi.sendSock(this.thetaParam, this.planTheta0Ret);

        // if(this.phaseIndex === 0)
        // {
        //   this.thetaParam.Type = "Theta0Plan";
        //   this.socketApi.sendSock(this.thetaParam, this.planTheta0Ret);
        // }
        //
        // if(this.phaseIndex === 1)
        // {
        //   this.thetaParam.Type = "AlphaxPlan";
        //
        //   this.thetaParam.alphaxL = this.alphaxL;
        //   this.thetaParam.st0h = this.st0h;
        //   this.thetaParam.st0s = this.st0s;
        //
        //   this.socketApi.sendSock(this.thetaParam, this.planTheta1Ret);
        // }
      },
      Sim(phase){
        this.thetaParam.ClMa=this.aeroAnalyzRet.ClMa;
        this.thetaParam.Clalpha=this.aeroAnalyzRet.Clalpha;
        this.thetaParam.Clalpha2=this.aeroAnalyzRet.Clalpha2;
        this.thetaParam.Cd1Ma=this.aeroAnalyzRet.Cd1Ma;
        this.thetaParam.Cd1alpha=this.aeroAnalyzRet.Cd1alpha;
        this.thetaParam.Cd1alpha2=this.aeroAnalyzRet.Cd1alpha2;
        this.thetaParam.Cd20=this.aeroAnalyzRet.Cd20;
        this.thetaParam.Cd2Ma=this.aeroAnalyzRet.Cd2Ma;
        this.thetaParam.Cd2alpha=this.aeroAnalyzRet.Cd2alpha;
        this.thetaParam.Cd2alpha2=this.aeroAnalyzRet.Cd2alpha2;

        this.thetaParam.m0 = this.paramEstOutput.m0;
        this.thetaParam.dm = this.paramEstOutput.dm;
        this.thetaParam.ts = this.paramEstOutput.t01;
        this.thetaParam.p0 = this.paramEstOutput.P0;
        this.thetaParam.Sm = this.paramEstOutput.Sen;

        if(phase === 0) {
          this.thetaParam.Type = "Theta0Sim";
          // this.thetaParam.theta0 = this.theta0;
          this.thetaParam.theta0 = this.theta0*Math.PI/180.0;

          this.socketApi.sendSock(this.thetaParam, this.simTheta0Ret);
        }

        if(phase === 1){
          this.thetaParam.Type = "AlphaxSim";
          // this.thetaParam.theta0 = this.theta0;
          this.thetaParam.theta0 = this.theta0*Math.PI/180.0;

          this.thetaParam.st0h = this.st0h;
          this.thetaParam.st0s = this.st0s;

          // this.thetaParam.alphaxUp = this.alphaxUp;
          // this.thetaParam.alphaxDown = this.alphaxDown;

          this.thetaParam.alphaxUp = this.alphaxUp*Math.PI/180.0;
          this.thetaParam.alphaxDown = this.alphaxDown*Math.PI/180.0;
          this.thetaParam.alphaxT = this.alphaxT;

          this.socketApi.sendSock(this.thetaParam, this.simTheta1Ret);
        }
        if(phase === 2)
        {
          this.thetaParam.Type = "TerGuideSim";

          let arrLen = this.phase2SimDatas.vArr.length;
          this.thetaParam.v0_1 = this.phase2SimDatas.vArr[arrLen-1]*Math.cos(this.phase2SimDatas.thetaArr[arrLen-1]);
          this.thetaParam.v0_2 = this.phase2SimDatas.vArr[arrLen-1]*Math.sin(this.phase2SimDatas.thetaArr[arrLen-1]);

          this.thetaParam.p0_1 = this.phase2SimDatas.lArr[arrLen-1];
          this.thetaParam.p0_2 = this.phase2SimDatas.hArr[arrLen-1];

          this.socketApi.sendSock(this.thetaParam, this.simTheta2Ret);
        }
      },
      planTheta0Ret(data){
        // this.theta0 = data.theta0_out;
        this.theta0 = (data.theta0_out*180.0/Math.PI).toFixed(3);

        this.Sim(0);
      },
      simTheta0Ret(data){
        //console.log("phase1:"+JSON.stringify(data))

        this.phase1SimDatas.phase = [];
        this.phase1SimDatas.tArr = [];
        this.phase1SimDatas.hArr = [];
        this.phase1SimDatas.lArr = [];
        this.phase1SimDatas.vArr = [];
        this.phase1SimDatas.alphaArr = [];
        this.phase1SimDatas.thetaArr = [];
        this.phase1SimDatas.gArr = [];

        this.phase2SimDatas.phase = [];
        this.phase2SimDatas.tArr = [];
        this.phase2SimDatas.hArr = [];
        this.phase2SimDatas.lArr = [];
        this.phase2SimDatas.vArr = [];
        this.phase2SimDatas.alphaArr = [];
        this.phase2SimDatas.thetaArr = [];
        this.phase2SimDatas.gArr = [];

        this.phase3SimDatas.phase = [];
        this.phase3SimDatas.tArr = [];
        this.phase3SimDatas.hArr = [];
        this.phase3SimDatas.lArr = [];
        this.phase3SimDatas.vArr = [];
        this.phase3SimDatas.alphaArr = [];
        this.phase3SimDatas.thetaArr = [];
        this.phase3SimDatas.gArr = [];

        this.lineSHOption.series[0].data = [];
        this.lineSHOption.series[1].data = [];
        this.lineSHOption.series[2].data = [];

        this.lineTSOption.series[0].data = [];
        this.lineTSOption.series[1].data = [];
        this.lineTSOption.series[2].data = [];

        this.lineTHOption.series[0].data = [];
        this.lineTHOption.series[1].data = [];
        this.lineTHOption.series[2].data = [];

        this.lineTAOption.series[0].data = [];
        this.lineTAOption.series[1].data = [];
        this.lineTAOption.series[2].data = [];

        this.lineTGOption.series[0].data = [];
        this.lineTGOption.series[1].data = [];
        this.lineTGOption.series[2].data = [];

        this.lineTTOption.series[0].data = [];
        this.lineTTOption.series[1].data = [];
        this.lineTTOption.series[2].data = [];

        this.simDatas = [];

        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase1SimDatas.phase.push(data.phase[i]);
          this.phase1SimDatas.tArr.push(data.tArr[i]);
          this.phase1SimDatas.hArr.push(data.hArr[i]);
          this.phase1SimDatas.lArr.push(data.lArr[i]);
          this.phase1SimDatas.vArr.push(data.vArr[i]);
          this.phase1SimDatas.thetaArr.push(data.thetaArr[i]);
          this.phase1SimDatas.alphaArr.push(data.alphaArr[i]);
          this.phase1SimDatas.gArr.push(data.gArr[i]);

          this.lineSHOption.series[0].data.push([data.lArr[i]/1000.0,data.hArr[i]/1000.0]);
          this.lineSHOption.series[1].data.push("");
          this.lineSHOption.series[2].data.push("");

          this.lineTSOption.series[0].data.push([data.tArr[i],data.vArr[i]]);
          this.lineTSOption.series[1].data.push("");
          this.lineTSOption.series[2].data.push("");

          this.lineTHOption.series[0].data.push([data.tArr[i],data.hArr[i]/1000.0]);
          this.lineTHOption.series[1].data.push("");
          this.lineTHOption.series[2].data.push("");

          this.lineTTOption.series[0].data.push([data.tArr[i],data.thetaArr[i]*180.0/Math.PI]);
          this.lineTTOption.series[1].data.push("");
          this.lineTTOption.series[2].data.push("");

          this.lineTGOption.series[0].data.push([data.tArr[i],data.gArr[i]]);
          this.lineTGOption.series[1].data.push("");
          this.lineTGOption.series[2].data.push("");

          this.lineTAOption.series[0].data.push([data.tArr[i],data.alphaArr[i]*180.0/Math.PI]);
          this.lineTAOption.series[1].data.push("");
          this.lineTAOption.series[2].data.push("");

          this.simDatas.push({t:data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})

        }

        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);
        this.ttChart.setOption(this.lineTTOption);
        this.tgChart.setOption(this.lineTGOption);

        let size = data.hArr.length;
        this.st0h = data.hArr[size-1];
        this.st0s = data.vArr[size-1];
        this.alphaxL = this.paramEstInput.R * 1000 - data.lArr[size - 1]
        this.$store.commit('setPhase1Datas', this.phase1SimDatas)


        //自动执行滑翔段规划
        this.thetaParam.Type = "AlphaxPlan";

        this.thetaParam.alphaxL = this.alphaxL;
        this.thetaParam.st0h = this.st0h;
        this.thetaParam.st0s = this.st0s;

        this.socketApi.sendSock(this.thetaParam, this.planTheta1Ret);

      },

      planTheta1Ret(data){
        // this.alphaxUp = data.alphaxUp;
        // this.alphaxDown = data.alphaxDown;

        this.alphaxUp = (data.alphaxUp*180.0/Math.PI).toFixed(3);
        this.alphaxDown = (data.alphaxDown*180.0/Math.PI).toFixed(3);

        this.alphaxT = data.alphaxT;

        this.Sim(1);
      },

      simTheta1Ret(data){
        //console.log("phase2:"+JSON.stringify(data))

        let tSize = this.phase1SimDatas.tArr.length;
        let lastT = this.phase1SimDatas.tArr[tSize-1];
        let lastL = this.phase1SimDatas.lArr[tSize-1];
        let lastV = this.phase1SimDatas.vArr[tSize-1];
        let lastH = this.phase1SimDatas.hArr[tSize-1];
        let lastA = this.phase1SimDatas.alphaArr[tSize-1];
        let lastTheta = this.phase1SimDatas.thetaArr[tSize-1];
        let lastG = this.phase1SimDatas.gArr[tSize-1];

        this.lineSHOption.series[1].data[tSize-1] = [lastL/1000.0,lastH/1000.0];

        this.lineTSOption.series[1].data[tSize-1] = [lastT,lastV];

        this.lineTHOption.series[1].data[tSize-1] = [lastT,lastH/1000.0];

        this.lineTAOption.series[1].data[tSize-1] = [lastT,lastA*180.0/Math.PI];

        this.lineTGOption.series[1].data[tSize-1] = [lastT,lastG];

        this.lineTTOption.series[1].data[tSize-1] = [lastT,lastTheta*180.0/Math.PI];


        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase2SimDatas.phase.push(data.phase[i]);
          this.phase2SimDatas.tArr.push(lastT+data.tArr[i]);
          this.phase2SimDatas.hArr.push(data.hArr[i]);
          this.phase2SimDatas.lArr.push(lastL+data.lArr[i]);
          this.phase2SimDatas.vArr.push(data.vArr[i]);
          this.phase2SimDatas.thetaArr.push(data.thetaArr[i]);
          this.phase2SimDatas.alphaArr.push(data.alphaArr[i]);
          this.phase2SimDatas.gArr.push(data.gArr[i]);

          this.lineSHOption.series[1].data.push([(lastL+data.lArr[i])/1000.0,data.hArr[i]/1000.0]);
          this.lineSHOption.series[2].data.push("");

          this.lineTSOption.series[1].data.push([lastT+data.tArr[i],data.vArr[i]]);
          this.lineTSOption.series[2].data.push("");

          this.lineTHOption.series[1].data.push([lastT+data.tArr[i],data.hArr[i]/1000.0]);
          this.lineTHOption.series[2].data.push("");

          this.lineTAOption.series[1].data.push([lastT+data.tArr[i],data.alphaArr[i]*180.0/Math.PI]);
          this.lineTAOption.series[2].data.push("");

          this.lineTGOption.series[1].data.push([lastT+data.tArr[i],data.gArr[i]]);
          this.lineTGOption.series[2].data.push("");

          this.lineTTOption.series[1].data.push([lastT+data.tArr[i],data.thetaArr[i]*180.0/Math.PI]);
          this.lineTTOption.series[2].data.push("");

          this.simDatas.push({t:lastT+data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})
        }

        let size = data.lArr.length;
        this.thetaParam.tp0_1 = lastL+data.lArr[size-1]+5000.0;


        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);
        this.ttChart.setOption(this.lineTTOption);
        this.tgChart.setOption(this.lineTGOption);

        this.$store.commit('setPhase2Datas', this.phase2SimDatas)

        this.Sim(2)
      },


      planTheta2Ret(){
        // console.log(data)
      },

      simTheta2Ret(data){
        //console.log("phase3:"+JSON.stringify(data))

        let tSize = this.phase2SimDatas.tArr.length;
        let lastT = this.phase2SimDatas.tArr[tSize-1];
        //let lastL = this.phase2SimDatas.lArr[tSize-1];
        let lastV = this.phase2SimDatas.vArr[tSize-1];
        let lastH = this.phase2SimDatas.hArr[tSize-1];
        let lastA = this.phase2SimDatas.alphaArr[tSize-2];
        let lastTheta = this.phase2SimDatas.thetaArr[tSize-1];
        let lastG = this.phase2SimDatas.gArr[tSize-2];

        this.lineTSOption.series[2].data[tSize-1] = [lastT,lastV];

        this.lineTHOption.series[2].data[tSize-1] = [lastT,lastH/1000.0];

        this.lineTTOption.series[2].data[tSize-1] = [lastT,lastTheta*180.0/Math.PI];

        // this.lineTAOption.series[2].data[tSize-2] = [lastT,lastA*180.0/Math.PI];
        //
        // this.lineTGOption.series[2].data[tSize-2] = [lastT,lastG];

        this.lineTAOption.series[2].data[tSize-1] = [lastT-0.1,lastA*180.0/Math.PI];

        this.lineTGOption.series[2].data[tSize-1] = [lastT-0.1,lastG];

        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase3SimDatas.phase.push(data.phase[i]);
          this.phase3SimDatas.tArr.push(lastT+data.tArr[i]);
          this.phase3SimDatas.hArr.push(data.hArr[i]);
          this.phase3SimDatas.lArr.push(data.lArr[i]);
          this.phase3SimDatas.vArr.push(data.vArr[i]);
          this.phase3SimDatas.thetaArr.push(data.thetaArr[i]);
          this.phase3SimDatas.alphaArr.push(data.alphaArr[i]);
          this.phase3SimDatas.gArr.push(data.gArr[i]);

          this.lineSHOption.series[2].data.push([data.lArr[i]/1000.0,data.hArr[i]/1000.0]);

          this.lineTSOption.series[2].data.push([lastT+data.tArr[i],data.vArr[i]]);

          this.lineTHOption.series[2].data.push([lastT+data.tArr[i],data.hArr[i]/1000.0]);

          this.lineTAOption.series[2].data.push([lastT+data.tArr[i],data.alphaArr[i]*180.0/Math.PI]);

          this.lineTGOption.series[2].data.push([lastT+data.tArr[i],data.gArr[i]]);

          this.lineTTOption.series[2].data.push([lastT+data.tArr[i],data.thetaArr[i]*180.0/Math.PI]);

          this.simDatas.push({t:lastT+data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})
        }

        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);
        this.ttChart.setOption(this.lineTTOption);
        this.tgChart.setOption(this.lineTGOption);

        this.$store.commit('setPhase3Datas', this.phase3SimDatas)

      },

      initData(obj,int_data, name) {
        obj.initDataByObject(int_data, {
          x: "time",
          y: 'view',
          type: "line",
        }, name);
        obj.setSeriesStyle(name, "default");
        obj.setChartStyle("default");
      },
      setData(obj,set_data) {
        obj.setDataByObject(set_data, {
          x: "time",
          y: 'view'
        }, 'iw');
      },
    }
  }
</script>

<style scoped>
.hw-100{
  width: 100%;height: 100%
}
.v-input__slot{
  border: 1px solid #ffffff !important;
}
v-text-field input{
  border: 1px solid #ffffff !important;
}
.h-100{
  height: 100%
}
.w-100{
  width: 100%;
}
.flex-center{
  display: flex;
  align-items: center;
}
</style>
