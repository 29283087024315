
export function exportExcel(fields, attribute, list, sheetName, fileName){
  require.ensure([], () => {
    const {export_json_to_excel} = require('./Export2Excel')
    const data = formatJson(attribute, list)
    export_json_to_excel(fields, data, sheetName, fileName + '(' + new Date().getTime() + ')')  // + new Date('YYYY-MM-DD-HH.mm.ss')
  })
}

// 导出多个sheet
export function exportExcelMulSheet(sheets, fileName){
  require.ensure([], () => {
    const {export_json_to_excel_mul_sheet} = require('./Export2Excel')
    const result = []
    sheets.forEach(sheet => {
      result.push({
        th: sheet.fields,
        jsonData: formatJson(sheet.attribute, sheet.list),
        sheetName: sheet.sheetName,
      })
    })
    // const data = formatJson(attribute, list)
    export_json_to_excel_mul_sheet(result, fileName + '(' + new Date().getTime() + ')')  // + new Date('YYYY-MM-DD-HH.mm.ss')
  })
}
function formatJson(filterVal, jsonData){
  return jsonData.map(v => filterVal.map(f => v[f]))
}

// 导出报表 demo
// exportReport(){
  // demo-1 单个sheet
  // const fields = ['名称', '高度']
  // const attribute = ['name', 'alt']
  // const list = [
  //   {name: 'n-1', alt: 100},
  //   {name: 'n-2', alt: 200},
  //   {name: 'n-3', alt: 300},
  //   {name: 'n-4', alt: 400},
  // ]
  // exportExcel(fields, attribute, list, 'xxx', '实验报告')

  // demo-2 多个sheet
  // let listArr = [
  //   {
  //     sheetName: 'xxx',
  //     fields: ['名称', '高度'],
  //     attribute: ['name', 'alt'],
  //     list: [
  //       {name: 'n-1', alt: 100},
  //       {name: 'n-2', alt: 200},
  //       {name: 'n-3', alt: 300},
  //       {name: 'n-4', alt: 400},
  //     ]
  //   },
  //   {
  //     sheetName: 'yyy',
  //     fields: ['名称', '高度'],
  //     attribute: ['name', 'alt'],
  //     list: [
  //       {name: 'n-1', alt: 100},
  //       {name: 'n-2', alt: 200},
  //       {name: 'n-3', alt: 300},
  //       {name: 'n-4', alt: 400},
  //     ]
  //   },
  //   {
  //     sheetName: 'zzz',
  //     fields: ['名称', '高度'],
  //     attribute: ['name', 'alt'],
  //     list: [
  //       {name: 'n-1', alt: 100},
  //       {name: 'n-2', alt: 200},
  //       {name: 'n-3', alt: 300},
  //       {name: 'n-4', alt: 400},
  //     ]
  //   },
  // ]
  // exportExcelMulSheet(listArr, '实验报告')
// }
