<template>
  <v-app>
  <div class="hw-100" style="background-color: #808080">

    <div style="height:70%"  >
      <v-row>
        <v-col cols="8">
          <v-row >
            <v-col cols="12">
              <div id="lineSH1" style="height: 400px;width: 1200px;"/>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="4">
              <div id="lineTS1" style="height: 300px;width: 400px;"/>
            </v-col>
            <v-col cols="4">
              <div id="lineTH1" style="height: 300px;width: 400px;"/>
            </v-col>
            <v-col cols="4">
              <div id="lineTA1" style="height: 300px;width: 400px;"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-simple-table dense fixed-header height="700px" style="background-color: #808080;color: white;">
            <template v-slot:default>
              <thead >
              <tr>
                <th style="padding:0px 8px;background-color: #86cafd;"
                    v-for="(items, i) in simHeaders"
                    :key="i">{{ items.text }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(items, i) in simDatas"
                  :key="i">
                <td style="padding:0px 5px;width: 6rem">{{ items.t.toFixed(3) }}</td>
                <td style="padding:0px 10px">{{ items.s.toFixed(3) }}</td>
                <td style="padding:0px 10px">{{ items.h.toFixed(3) }}</td>
                <td style="padding:0px 10px">{{ items.a.toFixed(3) }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>

    <div style="position: absolute;bottom: 0;width:100%;height:25%;z-index: 2" >
      <v-card class="hw-100" flat color="#1e1e1e80" style="background-color: #40404080">
        <div class="hw-100 ">
          <v-form class="hw-100" >
            <v-row>
            <v-col cols="10">
              <v-row class="row-fixh30 pl-10">
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"起飞质量(kg): " }}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: #fffd6a;font-size: 12px">{{paramEstOutput.m0.toFixed(3)}}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"秒耗量(kg): " }}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: #fffd6a;font-size: 12px">{{ paramEstOutput.dm.toFixed(3)}}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"关机时间(s): " }}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: #fffd6a;font-size: 12px">{{paramEstOutput.t01.toFixed(3)}}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"地面推力(N): " }}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: #fffd6a;font-size: 12px">{{paramEstOutput.P0.toFixed(3)}}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"特征面积(m^2): "}}</v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: #fffd6a">{{paramEstOutput.Sen.toFixed(3)}}</v-col>
             </v-row>
              <v-row class="row-fixh30 pl-10">
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"ClMa: " }}</v-col>
                <v-col cols="1">
                    <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                                  type="number"
                                  dense
                                  hide-details
                                  v-model.number=aeroAnalyzRet.ClMa></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Clalpha" }}</v-col>
                <v-col cols="1">
                    <v-text-field outlined :rules="[ v=> !!v||'输入不能为空']"
                                  type="number"
                                  dense
                                  hide-details
                                  v-model.number=aeroAnalyzRet.Clalpha></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Clalpha2" }}</v-col>
                <v-col cols="1">
                    <v-text-field outlined
                                  :rules="[ v=> !!v||'输入不能为空']"
                                  type="number"
                                  dense
                                  hide-details
                                  v-model.number=aeroAnalyzRet.Clalpha2></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd1Ma" }}</v-col>
                <v-col cols="1">
                    <v-text-field outlined
                                  dense
                                  hide-details
                                  :rules="[ v=> !!v||'输入不能为空']"
                                  type="number" v-model.number=aeroAnalyzRet.Cd1Ma></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd1alpha" }}</v-col>
                <v-col cols="1">
                    <v-text-field outlined
                                  dense
                                  hide-details
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                        v-model.number=aeroAnalyzRet.Cd1alpha
                    ></v-text-field>
                  </v-col>

              </v-row>
              <v-row class="row-fixh30 pl-10" style="margin-top: -20px;">
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd1alpha2" }}</v-col>
                  <v-col cols="1">
                    <v-text-field outlined
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                                  dense
                                  hide-details
                        v-model.number=aeroAnalyzRet.Cd1alpha2
                    ></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd20" }}</v-col>
                  <v-col cols="1">
                    <v-text-field outlined
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                                  dense
                                  hide-details
                        v-model.number=aeroAnalyzRet.Cd20
                    ></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2Ma" }}</v-col>
                  <v-col cols="1">
                    <v-text-field outlined
                                  dense
                                  hide-details
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                        v-model.number=aeroAnalyzRet.Cd2Ma
                    ></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2alpha" }}</v-col>
                  <v-col cols="1">
                    <v-text-field outlined
                                  dense
                                  hide-details
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                        v-model.number=aeroAnalyzRet.Cd2alpha
                    ></v-text-field>
                  </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"Cd2alpha2" }}</v-col>
                  <v-col cols="1">
                    <v-text-field outlined
                                  dense
                                  hide-details
                        :rules="[ v=> !!v||'输入不能为空']"
                        type="number"
                        v-model.number=aeroAnalyzRet.Cd2alpha2
                    ></v-text-field>
                  </v-col>
                <v-col v-show="phaseIndex === 2" cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"仿真步长" }}</v-col>
                <v-col v-show="phaseIndex === 2" cols="1">
                  <v-text-field outlined
                                dense
                                hide-details
                                :rules="[ v=> !!v||'输入不能为空']"
                                type="number"
                                v-model.number=thetaParam.step
                  ></v-text-field>
                </v-col>

                </v-row>

              <v-row v-show="phaseIndex === 0" class="row-fixh30 pl-10" style="margin-top: -20px;">
                  <v-col cols="1" class="px-3;mx-5"  style="color: white;font-size: 12px;">{{"theta0"}}</v-col>
                  <v-col cols="1">
                    <v-text-field  outlined
                                   dense
                                   hide-details
                                   style="color:#00e487;"
                                   :rules="[ v=> !!v||'输入不能为空']"
                                   type="number"
                                   v-model.number= "theta0"
                    ></v-text-field>
                  </v-col>
              </v-row>
              <v-row v-show="phaseIndex === 1" class="row-fixh30 pl-10" style="margin-top: -20px;">
                <v-col cols="1" class="px-3;mx-5"  style="color: white;font-size: 12px;">{{"滑翔段射程" }}</v-col>
                <v-col cols="1">
                  <v-text-field outlined
                                dense
                                hide-details
                                :rules="[ v=> !!v||'输入不能为空']"
                                type="number"
                                v-model.number= "alphaxL"
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"起滑高度" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= "st0h"
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"起滑速度" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= "st0s"
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"上拉攻角" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= "alphaxUp"
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"下压攻角" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= "alphaxDown"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"上拉时刻" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= "alphaxT"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-show="phaseIndex === 2" class="row-fixh30 pl-10" style="margin-top: -20px;">
                <v-col cols="1" class="px-3;mx-5"  style="color: white;font-size: 12px;">{{"目标速度X" }}</v-col>
                <v-col cols="1">
                  <v-text-field outlined
                                dense
                                hide-details
                                :rules="[ v=> !!v||'输入不能为空']"
                                type="number"
                                v-model.number= thetaParam.tv0_1
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"目标速度Y" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= thetaParam.tv0_2
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"目标速度Z" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= thetaParam.tv0_3
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"目标位置X" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= thetaParam.tp0_1
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"目标位置Y" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= thetaParam.tp0_2
                  ></v-text-field>
                </v-col>

                <v-col cols="1" class="px-3;mx-5" style="color: white;font-size: 12px;">{{"目标位置Z" }}</v-col>
                <v-col cols="1" >
                  <v-text-field  outlined
                                 dense
                                 hide-details
                                 :rules="[ v=> !!v||'输入不能为空']"
                                 type="number"
                                 v-model.number= thetaParam.tp0_3
                  ></v-text-field>
                </v-col>

              </v-row>
            </v-col>
            <v-col cols="2" >
              <v-radio-group v-model="phaseIndex" mandatory row>
                <v-radio :value="0" label="主动段" dark></v-radio>
                <v-radio :value="1" label="滑翔段" dark></v-radio>
                <v-radio :value="2" label="末制导" dark></v-radio>
              </v-radio-group>
              <div style="text-align: center;">
<!--                <v-btn class="xy-btn mt-5 mr-10" width="180px" height="50px" x-large color="#1e1e1e50" @click="Plan" dark>-->
<!--                  规划-->
<!--                </v-btn>-->
                <v-btn class="xy-btn mt-5 mr-10" width="180px" height="50px" x-large color="#1e1e1e50" @click="Plan" dark>
                  仿真
                </v-btn>
              </div>
            </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </div>
  </div>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  // import lineEcharts from '../components/chart/lineChart.vue';
  import echarts from 'echarts';
  export default {
    name: 'index',
    props:['pageIndex'],

    mounted() {

      this.shChart = echarts.init(document.getElementById('lineSH1'))
      this.shChart.setOption(this.lineSHOption)

      this.tsChart = echarts.init(document.getElementById('lineTS1'))
      this.tsChart.setOption(this.lineTSOption)

      this.thChart = echarts.init(document.getElementById('lineTH1'))
      this.thChart.setOption(this.lineTHOption)

      this.taChart = echarts.init(document.getElementById('lineTA1'))
      this.taChart.setOption(this.lineTAOption)
    },
    data(){
      return{
        phaseIndex:0,
        pageID:3,
        theta0:0.0,
        alphaxL:400000.0,
        st0h:50000.0,
        st0s:1500.0,
        alphaxUp:0.0,
        alphaxDown:0.0,
        alphaxT:0.0,
        shChart:'',
        tsChart:'',
        thChart:'',
        thetaParam:{
          Type:"",
          m0:0.0,
          dm:0.0,
          ts:0.0,
          p0:0.0,
          Sm:0.0,
          htop:0.0,
          theta0:0.0,
          R0:6.0,
          step:1.0,
          v0_1:0.0,
          v0_2:0.0,
          v0_3:0.0,
          p0_1:0.0,
          p0_2:0.0,
          p0_3:0.0,
          tv0_1:0.0,
          tv0_2:0.0,
          tv0_3:0.0,
          tp0_1:206000.0,
          tp0_2:0.0,
          tp0_3:0.0,
          ClMa:0.0,
          Clalpha:0.0,
          Clalpha2:0.0,
          Cd1Ma:0.0,
          Cd1alpha:0.0,
          Cd1alpha2:0.0,
          Cd20:0.0,
          Cd2Ma:0.0,
          Cd2alpha:0.0,
          Cd2alpha2:0.0
        },
        form_data:{},
        lineSHOption:{
          title: {
            text: '射程-高度'
          },
          yAxis: {
          },
          xAxis: {
            type:'value',
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffffff'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#00ffff'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTSOption:{
          title: {
            text: '时间-速度'
          },
          yAxis: {},
          xAxis: {
            type:'value',
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffffff'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#00ffff'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTHOption:{
          title: {
            text: '时间-高度'
          },
          grid:{
            x:50,
          },
          yAxis: {},
          xAxis: {
            boundaryGap:true,
            type:'value',
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffffff'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#00ffff'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        lineTAOption:{
          title: {
            text: '时间-方位角'
          },
          yAxis: {},
          xAxis: {
            type:'value',
          },
          series: [
            {
              name: 'S-H-1',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ffffff'
                  }
                }
              }
            },
            {
              name: 'S-H-2',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#00ffff'
                  }
                }
              }
            },
            {
              name: 'S-H-3',
              type: 'line',
              symbol:'none',
              smooth:true,
              data:[],
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:'#ff00ff'
                  }
                }
              }
            }
          ]
        },
        simHeaders: [
          {
            text: "时间"
          },
          {
            text: "速度"
          },
          {
            text: "高度"
          },
          {
            text: "方位角"
          }
        ],
        simDatas: [],
        phase1SimDatas:{
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          theta:[]
        },
        phase2SimDatas:{
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          theta:[]
        },
        phase3SimDatas:{
          tArr:[],
          hArr:[],
          lArr:[],
          vArr:[],
          theta:[]
        }
      }
    },
    computed:{
      ...mapState(['paramEstInput', 'paramEstOutput','aeroAnalyzRet'])
    },
    watch:{
      pageIndex:function (newVal){
        this.pageID = newVal
      }
    },
    methods:{
      Plan(){

        this.thetaParam.ClMa=this.aeroAnalyzRet.ClMa;
        this.thetaParam.Clalpha=this.aeroAnalyzRet.Clalpha;
        this.thetaParam.Clalpha2=this.aeroAnalyzRet.Clalpha2;
        this.thetaParam.Cd1Ma=this.aeroAnalyzRet.Cd1Ma;
        this.thetaParam.Cd1alpha=this.aeroAnalyzRet.Cd1alpha;
        this.thetaParam.Cd1alpha2=this.aeroAnalyzRet.Cd1alpha2;
        this.thetaParam.Cd20=this.aeroAnalyzRet.Cd20;
        this.thetaParam.Cd2Ma=this.aeroAnalyzRet.Cd2Ma;
        this.thetaParam.Cd2alpha=this.aeroAnalyzRet.Cd2alpha;
        this.thetaParam.Cd2alpha2=this.aeroAnalyzRet.Cd2alpha2;

        this.thetaParam.htop = this.paramEstInput.h2;
        this.thetaParam.m0 = this.paramEstOutput.m0;
        this.thetaParam.dm = this.paramEstOutput.dm;
        this.thetaParam.ts = this.paramEstOutput.t01;
        this.thetaParam.p0 = this.paramEstOutput.P0;
        this.thetaParam.Sm = this.paramEstOutput.Sen;

        if(this.phaseIndex === 0)
        {
          this.thetaParam.Type = "Theta0Plan";
          this.socketApi.sendSock(this.thetaParam, this.planTheta0Ret);
        }

        if(this.phaseIndex === 1)
        {
          this.thetaParam.Type = "AlphaxPlan";

          this.thetaParam.alphaxL = this.alphaxL;
          this.thetaParam.st0h = this.st0h;
          this.thetaParam.st0s = this.st0s;

          this.socketApi.sendSock(this.thetaParam, this.planTheta1Ret);
        }

        if(this.phaseIndex === 2)
        {
          this.thetaParam.Type = "TerGuideSim";

          let arrLen = this.phase2SimDatas.vArr.length;
          this.thetaParam.v0_1 = this.phase2SimDatas.vArr[arrLen-1]*Math.cos(this.phase2SimDatas.thetaArr[arrLen-1]*Math.PI/180.0);
          this.thetaParam.v0_2 = this.phase2SimDatas.vArr[arrLen-1]*Math.sin(this.phase2SimDatas.thetaArr[arrLen-1]*Math.PI/180.0);

          this.thetaParam.p0_1 = this.phase2SimDatas.lArr[arrLen-1];
          this.thetaParam.p0_2 = this.phase2SimDatas.hArr[arrLen-1];

          this.socketApi.sendSock(this.thetaParam, this.simTheta2Ret);
        }

      },
      Sim(){
        this.thetaParam.ClMa=this.aeroAnalyzRet.ClMa;
        this.thetaParam.Clalpha=this.aeroAnalyzRet.Clalpha;
        this.thetaParam.Clalpha2=this.aeroAnalyzRet.Clalpha2;
        this.thetaParam.Cd1Ma=this.aeroAnalyzRet.Cd1Ma;
        this.thetaParam.Cd1alpha=this.aeroAnalyzRet.Cd1alpha;
        this.thetaParam.Cd1alpha2=this.aeroAnalyzRet.Cd1alpha2;
        this.thetaParam.Cd20=this.aeroAnalyzRet.Cd20;
        this.thetaParam.Cd2Ma=this.aeroAnalyzRet.Cd2Ma;
        this.thetaParam.Cd2alpha=this.aeroAnalyzRet.Cd2alpha;
        this.thetaParam.Cd2alpha2=this.aeroAnalyzRet.Cd2alpha2;

        this.thetaParam.m0 = this.paramEstOutput.m0;
        this.thetaParam.dm = this.paramEstOutput.dm;
        this.thetaParam.ts = this.paramEstOutput.t01;
        this.thetaParam.p0 = this.paramEstOutput.P0;
        this.thetaParam.Sm = this.paramEstOutput.Sen;

        if(this.phaseIndex === 0) {
          this.thetaParam.Type = "Theta0Sim";
          this.thetaParam.theta0 = this.theta0;

          this.socketApi.sendSock(this.thetaParam, this.simTheta0Ret);
        }

        if(this.phaseIndex === 1){
          this.thetaParam.Type = "AlphaxSim";
          this.thetaParam.theta0 = this.theta0;

          this.thetaParam.st0h = this.st0h;
          this.thetaParam.st0s = this.st0s;

          this.thetaParam.alphaxUp = this.alphaxUp;
          this.thetaParam.alphaxDown = this.alphaxDown;
          this.thetaParam.alphaxT = this.alphaxT;

          this.socketApi.sendSock(this.thetaParam, this.simTheta1Ret);
        }
        if(this.phaseIndex === 2)
        {
          this.thetaParam.Type = "TerGuideSim";

          let arrLen = this.phase2SimDatas.vArr.length;
          this.thetaParam.v0_1 = this.phase2SimDatas.vArr[arrLen-1]*Math.cos(this.phase2SimDatas.thetaArr[arrLen-1]*Math.PI/180.0);
          this.thetaParam.v0_2 = this.phase2SimDatas.vArr[arrLen-1]*Math.sin(this.phase2SimDatas.thetaArr[arrLen-1]*Math.PI/180.0);

          this.thetaParam.p0_1 = this.phase2SimDatas.lArr[arrLen-1];
          this.thetaParam.p0_2 = this.phase2SimDatas.hArr[arrLen-1];

          this.socketApi.sendSock(this.thetaParam, this.simTheta2Ret);
        }
      },
      planTheta0Ret(data){
        this.theta0 = data.theta0_out;

        this.Sim();
      },
      simTheta0Ret(data){
        //console.log("phase1:"+JSON.stringify(data))

        this.phase1SimDatas.tArr = [];
        this.phase1SimDatas.hArr = [];
        this.phase1SimDatas.lArr = [];
        this.phase1SimDatas.vArr = [];
        this.phase1SimDatas.thetaArr = [];

        this.phase2SimDatas.tArr = [];
        this.phase2SimDatas.hArr = [];
        this.phase2SimDatas.lArr = [];
        this.phase2SimDatas.vArr = [];
        this.phase2SimDatas.thetaArr = [];

        this.phase3SimDatas.tArr = [];
        this.phase3SimDatas.hArr = [];
        this.phase3SimDatas.lArr = [];
        this.phase3SimDatas.vArr = [];
        this.phase3SimDatas.thetaArr = [];


        //this.lineSHOption.xAxis.data = [];
        this.lineSHOption.series[0].data = [];
        this.lineSHOption.series[1].data = [];
        this.lineSHOption.series[2].data = [];

        //this.lineTSOption.xAxis.data = [];
        this.lineTSOption.series[0].data = [];
        this.lineTSOption.series[1].data = [];
        this.lineTSOption.series[2].data = [];

        //this.lineTHOption.xAxis.data = [];
        this.lineTHOption.series[0].data = [];
        this.lineTHOption.series[1].data = [];
        this.lineTHOption.series[2].data = [];

        //this.lineTAOption.xAxis.data = [];
        this.lineTAOption.series[0].data = [];
        this.lineTAOption.series[1].data = [];
        this.lineTAOption.series[2].data = [];

        this.simDatas = [];

        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase1SimDatas.tArr.push(data.tArr[i]);
          this.phase1SimDatas.hArr.push(data.hArr[i]);
          this.phase1SimDatas.lArr.push(data.lArr[i]);
          this.phase1SimDatas.vArr.push(data.vArr[i]);
          this.phase1SimDatas.thetaArr.push(data.thetaArr[i]);

          this.phase2SimDatas.hArr.push("-");
          this.phase2SimDatas.lArr.push("-");
          this.phase2SimDatas.vArr.push("-");
          this.phase2SimDatas.thetaArr.push("-");

          this.phase3SimDatas.hArr.push("-");
          this.phase3SimDatas.lArr.push("-");
          this.phase3SimDatas.vArr.push("-");
          this.phase3SimDatas.thetaArr.push("-");

          //this.lineSHOption.xAxis.data.push(data.vArr[i]);
          this.lineSHOption.series[0].data.push([data.lArr[i],data.hArr[i]]);
          this.lineSHOption.series[1].data.push("");
          this.lineSHOption.series[2].data.push("");

          //this.lineTSOption.xAxis.data.push(data.tArr[i]);
          this.lineTSOption.series[0].data.push([data.tArr[i],data.vArr[i]]);
          this.lineTSOption.series[1].data.push("");
          this.lineTSOption.series[2].data.push("");

          //this.lineTHOption.xAxis.data.push(data.tArr[i]);
          this.lineTHOption.series[0].data.push([data.tArr[i],data.hArr[i]]);
          this.lineTHOption.series[1].data.push("");
          this.lineTHOption.series[2].data.push("");

          //this.lineTAOption.xAxis.data.push(data.tArr[i]);
          this.lineTAOption.series[0].data.push([data.tArr[i],data.thetaArr[i]]);
          this.lineTAOption.series[1].data.push("");
          this.lineTAOption.series[2].data.push("");

          this.simDatas.push({t:data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})
        }

        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);

        let size = data.hArr.length;
        this.st0h = data.hArr[size-1];
        this.st0s = data.vArr[size-1];
      },

      planTheta1Ret(data){
        this.alphaxUp = data.alphaxUp;
        this.alphaxDown = data.alphaxDown;
        this.alphaxT = data.alphaxT;

        this.Sim();
      },

      simTheta1Ret(data){
        //console.log("phase2:"+JSON.stringify(data))

        let tSize = this.phase1SimDatas.tArr.length;
        let lastT = this.phase1SimDatas.tArr[tSize-1];
        let lastL = this.phase1SimDatas.lArr[tSize-1];
        let lastV = this.phase1SimDatas.vArr[tSize-1];
        let lastH = this.phase1SimDatas.hArr[tSize-1];
        let lastA = this.phase1SimDatas.thetaArr[tSize-1];

        //this.lineSHOption.xAxis.data[tSize-1] = lastV;
        this.lineSHOption.series[1].data[tSize-1] = [lastL,lastH];

        //this.lineTSOption.xAxis.data[tSize-1] = lastT;
        this.lineTSOption.series[1].data[tSize-1] = [lastT,lastV];

        //this.lineTHOption.xAxis.data[tSize-1] = lastT;
        this.lineTHOption.series[1].data[tSize-1] = [lastT,lastH];

        //this.lineTAOption.xAxis.data[tSize-1] = lastT;
        this.lineTAOption.series[1].data[tSize-1] = [lastT,lastA];


        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase2SimDatas.tArr.push(lastT+data.tArr[i]);
          this.phase2SimDatas.hArr.push(data.hArr[i]);
          this.phase2SimDatas.lArr.push(lastL+data.lArr[i]);
          this.phase2SimDatas.vArr.push(data.vArr[i]);
          this.phase2SimDatas.thetaArr.push(data.thetaArr[i]);

          this.phase3SimDatas.hArr.push("-");
          this.phase3SimDatas.lArr.push("-");
          this.phase3SimDatas.vArr.push("-");
          this.phase3SimDatas.thetaArr.push("-");

          //this.lineSHOption.xAxis.data.push(data.vArr[i]);
          this.lineSHOption.series[1].data.push([lastL+data.lArr[i],data.hArr[i]]);
          this.lineSHOption.series[2].data.push("");

          //this.lineTSOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTSOption.series[1].data.push([lastT+data.tArr[i],data.vArr[i]]);
          this.lineTSOption.series[2].data.push("");

          //this.lineTHOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTHOption.series[1].data.push([lastT+data.tArr[i],data.hArr[i]]);
          this.lineTHOption.series[2].data.push("");

          //this.lineTAOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTAOption.series[1].data.push([lastT+data.tArr[i],data.thetaArr[i]]);
          this.lineTAOption.series[2].data.push("");

          this.simDatas.push({t:lastT+data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})
        }

        let size = data.lArr.length;
        this.thetaParam.tp0_1 = lastL+data.lArr[size-1]+50000.0;


        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);

      },


      planTheta2Ret(){
        // console.log(data)
      },

      simTheta2Ret(data){
        //console.log("phase3:"+JSON.stringify(data))

        let tSize = this.phase2SimDatas.tArr.length;
        let lastT = this.phase2SimDatas.tArr[tSize-1];
        let lastL = this.phase2SimDatas.lArr[tSize-1];
        let lastV = this.phase2SimDatas.vArr[tSize-1];
        let lastH = this.phase2SimDatas.hArr[tSize-1];
        let lastA = this.phase2SimDatas.thetaArr[tSize-1];

        //this.lineSHOption.xAxis.data[tSize-1] = lastV;
        //this.lineSHOption.series[2].data[tSize-1] = [lastL,lastH];

        //this.lineTSOption.xAxis.data[tSize-1] = ;
        this.lineTSOption.series[2].data[tSize-1] = [lastT,lastV];

        //this.lineTHOption.xAxis.data[tSize-1] = lastT;
        this.lineTHOption.series[2].data[tSize-1] = [lastT,lastH];

        //this.lineTAOption.xAxis.data[tSize-1] = lastT;
        this.lineTAOption.series[2].data[tSize-1] = [lastT,lastA];


        for(let i=0;i<data.vArr.length;i++)
        {
          this.phase3SimDatas.tArr.push(lastT+data.tArr[i]);
          this.phase3SimDatas.hArr.push(data.hArr[i]);
          this.phase3SimDatas.lArr.push(data.lArr[i]);
          this.phase3SimDatas.vArr.push(lastL+data.vArr[i]);
          this.phase3SimDatas.thetaArr.push(data.thetaArr[i]);

          //this.lineSHOption.xAxis.data.push(data.vArr[i]);
          this.lineSHOption.series[2].data.push([data.lArr[i],data.hArr[i]]);

          //this.lineTSOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTSOption.series[2].data.push([lastT+data.tArr[i],data.vArr[i]]);

          //this.lineTHOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTHOption.series[2].data.push([lastT+data.tArr[i],data.hArr[i]]);

          //this.lineTAOption.xAxis.data.push(lastT+data.tArr[i]);
          this.lineTAOption.series[2].data.push([lastT+data.tArr[i],data.thetaArr[i]]);

          this.simDatas.push({t:lastT+data.tArr[i],s:data.vArr[i],h:data.hArr[i],a:data.thetaArr[i]})
        }

        this.shChart.setOption(this.lineSHOption);

        this.tsChart.setOption(this.lineTSOption);
        this.thChart.setOption(this.lineTHOption);
        this.taChart.setOption(this.lineTAOption);

      },

      initData(obj,int_data, name) {
        obj.initDataByObject(int_data, {
          x: "time",
          y: 'view',
          type: "line",
        }, name);
        obj.setSeriesStyle(name, "default");
        obj.setChartStyle("default");
      },
      setData(obj,set_data) {
        obj.setDataByObject(set_data, {
          x: "time",
          y: 'view'
        }, 'iw');
      },
    }
  }
</script>

<style scoped>
.hw-100{
  width: 100%;height: 100%
}
.v-input__slot{
  border: 1px solid #ffffff !important;
}
v-text-field input{
  border: 1px solid #ffffff !important;
}
.h-100{
  height: 100%
}
.w-100{
  width: 100%;
}
</style>